import React from 'react';
import convertFullNameToShortName from '@frontend/jetlend-core/src/formatters/convertFullNameToShortName';
import UserIcon from '@ui/ui/UserIcon/UserIcon';
import styles from './CurrentUserHeader.module.scss';
import {
    isMobile,
    useWindowSize,
} from '@ui/utils/responsive';

export interface IProps extends React.PropsWithChildren {
    /**
     * Имя текущего пользователя.
     */
    name: string;
    /**
     * Кастомная иконка пользователя.
     */
    image?: string;
    /**
     * Бадж, который будет отображаться в правом верхнем углу иконки пользователя.
     */
    badge?: React.ReactNode;
     /**
     * Идентификатор пользователя
     */
    id?: number;
}

/**
 * Компонент отображающий блок с информацией о текущем пользователе в боковой панели пользователя {@link UserSidePanel}.
 */
export default function CurrentUserHeader({
    name,
    image,
    badge,
    id,
    children,
}: IProps) {
    const { width: screenWidth } = useWindowSize();
    const isMobileScreen = isMobile(screenWidth);

    // На мобильном экране должно отображаться сокращенное имя пользователя для компактности
    const shortName = convertFullNameToShortName(name);

    return (
        <div className={styles['container']}>
            <div>
                <UserIcon
                    size="large"
                    name={name}
                    image={image}
                    badge={badge}
                    id={id}
                />
            </div>
            <div className={styles['name']}>
                {isMobileScreen ? shortName : name}
            </div>
            {children}
        </div>
    );
}