'use client';

import React from 'react';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import {
    CURRENCY_RUB,
    formatPercent,
} from '@frontend/jetlend-core/src/formatters/formatUtils';
import {
    buildWordForm,
    numWordForm,
} from '@frontend/jetlend-core/src/utils/langUtils';
import { ISectionBasedProps } from '@app/models/common/common';
import {
    IChartApiModel,
    ICompanyYearRevenueApiModel,
} from '@app/models/investments/investments';
import SectionTitle from '@app/components/server/ui/Section/SectionTitle';
import CompanyRevenueBarChart from './CompanyRevenueBarChart/CompanyRevenueBarChart';
import { companyRevenueByYearsHandler } from '@app/ducks/investments';
import styles from './InvestmentsCompanyRevenueChart.module.scss';
import { investorsJetLendStatisticsHandler } from '@app/ducks/investors/investors';
import { IInvestorsJetLendStatisticsApiModel } from '@app/models/investors/investors';
import CompanyRevenueTargetGoalChart from './CompanyRevenueTargetGoalChart/CompanyRevenueTargetGoalChart';

export type IProps = ISectionBasedProps<IChartApiModel>;

function buildChartArrowTitle(companyRevenue: ICompanyYearRevenueApiModel[], stats: IInvestorsJetLendStatisticsApiModel): React.ReactNode {
    if (!companyRevenue || !stats || companyRevenue.length < 2) {
        return null;
    }

    const firstPoint = companyRevenue[0];
    const lastPoint = companyRevenue[companyRevenue.length - 1];

    return (
        <>
            CAGR
            <tspan
                baselineShift="super"
                fill="#20836D"
                fontSize={12}
                fontFamily="inherit"
                fontWeight={600}
            >
                1
            </tspan>{' '}
            {firstPoint.year % 100}’&nbsp;-&nbsp;{lastPoint.year % 100}’: {formatPercent(stats.company_cagr)}
        </>
    );
}

export default function InvestmentsCompanyRevenueChart({
    section,
}: IProps) {
    const [ companyRevenue ] = useSimpleApiHandler(companyRevenueByYearsHandler);
    const [ stats ] = useSimpleApiHandler(investorsJetLendStatisticsHandler);

    return (
        <div className={styles['section']}>
            <SectionTitle
                tag="h2"
                mobileTitle={section.title}
                title={section.title}
            />
            <div className={styles['container']}>

                <div className={styles['revenue']}>
                    <div className={styles['title']}>
                        Выручка, млн {CURRENCY_RUB}
                    </div>

                    {companyRevenue && (
                        <CompanyRevenueBarChart
                            data={companyRevenue}
                            arrowTitle={buildChartArrowTitle(companyRevenue, stats)}
                        />
                    )}
                </div>
                <div className={styles['goal']}>
                    <div className={styles['title']}>
                        Тактическая цель
                    </div>
                    <div className={styles['description']}>
                        <div>
                            Увеличить выручку
                        </div>
                        <div className="text-primary">
                            в&nbsp;{stats?.company_revenue_target_multiplier}&nbsp;{buildWordForm(stats?.company_revenue_target_multiplier, [ 'раз', 'раза', 'раз' ])}{' '}
                            за&nbsp;{numWordForm(stats?.company_revenue_target_term, 'year')}
                        </div>
                    </div>

                    <CompanyRevenueTargetGoalChart
                        className={styles['goal__chart']}
                        title="2024"
                        description={`Ближайшие ${numWordForm(stats?.company_revenue_target_term, 'year')}`}
                        multiplier={stats?.company_revenue_target_multiplier}
                    />
                </div>
                <div className={styles['footer']}>
                    <div>
                        <sup>1</sup>
                        CAGR - среднегодовой темп роста
                    </div>
                </div>
            </div>
        </div>
    );
}