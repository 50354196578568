'use client';

import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import { renderMultilineContent } from '@frontend/jetlend-web-ui/src/utils/renderMultilineContent';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import dynamic from 'next/dynamic';
import { Suspense } from 'react';
import StepsSectionStepsCard from '@app/components/server/common/StepsSection/StepsSectionStepsCard/StepsSectionStepsCard';
import {
    IBaseSectionWithStepsModel,
    ISectionBasedProps,
    StepsSectionContentType,
} from '@app/models/common/common';
import styles from './InvestmentsMarketForecastChartSection.module.scss';
import SectionTitle from '@app/components/server/ui/Section/SectionTitle';
import { marketVolumeForecastByYearsHandler } from '@app/ducks/investments';

const LazyMarketForecastBarChart = dynamic(() => import('@app/components/client/investments/InvestmentsMarketForecastChartSection/MarketForecastBarChart/MarketForecastBarChart'), { ssr: false });

export default function InvestmentsMarketForecastChartSection({ section }: ISectionBasedProps<IBaseSectionWithStepsModel<StepsSectionContentType.CHART>>) {
    const content = section?.content;

    const [ data ] = useSimpleApiHandler(marketVolumeForecastByYearsHandler);

    return (
        <>
            <SectionTitle
                tag="h2"
                title={section.title}
                mobileTitle={section.title_mobile}
            />
            <div className={styles['container']}>
                <div className={buildClassNames(styles, ['block', 'chart'])}>
                    <h3 className={styles['block__title']}>
                        {renderMultilineContent(content.title)}
                    </h3>

                    {data && (
                        <Suspense>
                            <LazyMarketForecastBarChart data={data} />
                        </Suspense>
                    )}
                </div>
                <div className={buildClassNames(styles, ['block', 'steps'])}>
                    <h3 className={styles['block__title']}>
                        {section.description}
                    </h3>

                    <StepsSectionStepsCard {...section} />
                </div>
            </div>
            {content.source_title && content.source_url && (
                <div className={styles['source']}>
                    <span>Источник:</span>{' '}
                    <a href={content.source_url} target="_blank" rel="noopener noreferrer nofollow">{content.source_title}</a>
                </div>
            )}
            {content.source_url && !content.source_title && (
                <div className={styles['source']}>
                    <a href={content.source_url} target="_blank" rel="noopener noreferrer nofollow">Источник</a>
                </div>
            )}
        </>
    );
}