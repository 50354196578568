import React, {
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { createPortal } from 'react-dom';
import styles from './SelectionTooltip.module.scss';
import {
    ISelectionTooltipOptionsBase,
    ITooltipContent,
    IValue,
} from './SelectionTooltip.models';

export interface IProps<TValue> extends ISelectionTooltipOptionsBase<TValue> {
    /**
     * Значение в пикскелях по оси
     */
    axisValues: {x: number; y: number};

    /**
     * Текущая ширина графика
     */
    width: number;

    /**
     * Значение по оси y1
     */
    y1?: number;

    /**
     * Значение по оси y2
     */
    y2?: number;

    /**
     * Ширина столбика, чтобы отображать двигающийся задник такой же ширины, что и столбик
     */
    lineWidth: number;

    /**
     * Значения, над которым находится курсор
     */
    selectedValue: TValue | Record<string, TValue> | null;
}

// Делей для включения плавной анимации
const TRANSITION_DELAY = 110;

/**
 * Элемент для работы с ховером на графиках
 */
export default function SelectionTooltip<TValue extends IValue>({
    y2,
    y1,
    lineWidth,
    selectedValue,
    width,
    axisValues,
    tooltipTitle,
    tooltipContent,
    tooltipColorMarker,
}: Partial<IProps<TValue>>) {
    const lineRef = useRef<SVGLineElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);

    const tooltipPositionRef = useRef({
        top: 0,
        left: 0,
    });

    const tooltipPosition = tooltipPositionRef.current;

    const [transition, setTransition] = useState(false);

    /**
     * Значение по Y для тултипа
     */
    const tooltipYValueRange = useMemo(() => {
        const lowerLineValue = Math.abs(y2-y1);
        const tooltipRect = tooltipRef.current?.getBoundingClientRect();

        // Получаем значение для точного отображения тултипа по Y координате
        const yValue = axisValues?.y-y1;

        // Не выходить за нижнюю границу графика
        if (yValue+tooltipRect?.height > lowerLineValue) {
            return `calc(${lowerLineValue}px - 100%)`;
        }

        // Не выходить за верхнюю границу графика
        if (yValue < y1) {
            return `${y1}px`;
        }

        // Отображаем тултип на уровне, с верхним концом столбика
        return `${yValue}px`;
    }, [width, selectedValue, y2, y1, tooltipRef.current]);

    /**
     * Отобжраем тултип на нужном месте по X относительно главной линии
     */
    const tooltipXValueRange = useMemo(() => {
        const halfWidth = width/2;

        /**
         * Если находимся на первой половине графика, то отображаем с правой стороны,
         * чтобы график не уходил за пределы экрана
         */
        if (halfWidth < axisValues?.x) {
            return `calc(-${lineWidth/2}px - 101%)`;
        }

        /**
         * Если находимся на первой половине графика, то отображаем с левой стороны,
         * чтобы график не уходил за пределы экрана
         */
        return `calc(${lineWidth/2}px + 1%)`;
    }, [width, axisValues, lineWidth]);

    const formattedTooltipTitle = useMemo(() => {
        if (!selectedValue) {
            return;
        }

        if (tooltipTitle) {
            return tooltipTitle(selectedValue);
        }
    }, [selectedValue, tooltipTitle]);

    const formattedTooltipContent = useMemo(() => {
        if (!selectedValue) {
            return;
        }
        return tooltipContent(selectedValue);
    }, [tooltipContent, selectedValue]);

    useEffect(() => {
        if (!lineRef.current) {
            return;
        }
        const boundingClientRect = lineRef.current.getBoundingClientRect();
        const parentBoundingClientRect = lineRef.current.parentElement.getBoundingClientRect();
        const topValue = boundingClientRect.top + window.scrollY;
        const leftValue = parentBoundingClientRect.left + axisValues?.x;

        /**
         * Получаем позицию тултипа
         */
        tooltipPositionRef.current = ({
            top: topValue,
            left: leftValue,
        });

        if (!transition) {
            /**
             * Для корректного отображения, без дерганий в начале, делаем так,
             * чтобы анимация для translate появлялась чуть позже
             */
            setTimeout(() => {
                setTransition(true);
            }, TRANSITION_DELAY);
        }
    }, [lineRef.current, axisValues]);

    if (selectedValue === null) {
        return;
    }

    return (
        <>
            <line
                x1={0}
                x2={0}
                y1={y1}
                y2={y2}
                stroke="#0000000A"
                style={{
                    transform: `translateX(${axisValues?.x}px)`,
                }}
                className={styles['line']}
                strokeWidth={lineWidth}
                ref={lineRef}
            />
            {createPortal(
                <div
                    ref={tooltipRef}
                    className={styles['tooltip-container']}
                    style={{
                        ...tooltipPosition,
                        transform: `translate(${tooltipXValueRange}, ${tooltipYValueRange})`,
                        transition: transition && 'all 0.2s ease-out',
                    }}
                >
                    <div className={styles['tooltip-title']}>{formattedTooltipTitle}</div>
                    <div className={styles['tooltip-content']}>
                        {React.isValidElement(formattedTooltipContent)
                            ? formattedTooltipContent
                            : (
                                <div>
                                    {(formattedTooltipContent as unknown as ITooltipContent[]).map(item => {
                                        if (item === null) {
                                            return;
                                        }

                                        return (
                                            <div key={item.title} className={styles['tooltip-content-value']}>
                                                <div className="d-flex d-flex--gap-5 d-flex--v-center">
                                                    {tooltipColorMarker && (
                                                        <span
                                                            className={styles['tooltip-content-color-marker']}
                                                            style={{
                                                                background: item.color,
                                                            }}
                                                        />
                                                    )}
                                                    {item.title}:
                                                </div>
                                                <div>{item.value}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                    </div>
                </div>,
                document.body)}
        </>
    );
};
