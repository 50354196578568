'use client';

import React, {
    useMemo,
    useRef,
} from 'react';
import { useResizeObserver } from '@frontend/jetlend-web-ui/src/hooks/useResizeObserver';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import {
    VictoryAxis,
    VictoryBar,
    VictoryChart,
    VictoryCursorContainer,
} from 'victory';
import moment from 'moment/moment';
import { useSelectionTooltipContainer } from '@frontend/jetlend-web-ui/src/ui/victory/SelectionTooltip/SelectionTooltip.logic';
import styles from './InvestmentsCreditPortfolioChart.module.scss';
import InvestmentsCreditPortfolioChartAxisTick from '@app/components/client/investments/InvestmentsCreditPortfolio/InvestmentsCreditPortfolioChartAxisTick/InvestmentsCreditPortfolioChartAxisTick';
import {
    getBarCornerRadius,
    getChartPadding,
    getDomainPadding,
    getFormattedData,
    getXAxisTickValues,
    getYAxisValues,
} from './InvestmentsCreditPortfolioChart.logic';
import { creditPortfolioChartHandler } from '@app/ducks/investments';
import {
    IChartApiModel,
    QUARTERS_MAP,
} from '@app/models/investments/investments';
import YearsAxis from '@app/components/client/investments/InvestmentsCreditPortfolio/YearsAxis/YearsAxis';
import SectionTitle from '@app/components/server/ui/Section/SectionTitle';
import { ISectionBasedProps } from '@app/models/common/common';

/**
 * Стиль текста
 */
export const FONT_FAMILY = 'SF Pro Display, sans-serif';

const BAR_RATIO = 0.8;

/**
 * График кредитного портфеля
 */
export default function InvestmentsCreditPortfolioChart({ section }: ISectionBasedProps<IChartApiModel>) {
    const containerRef = useRef();
    // Ширина и высота графика для адаптивности
    const [width, height] = useResizeObserver(containerRef);
    const [data] = useSimpleApiHandler(creditPortfolioChartHandler);
    const domainPadding = getDomainPadding(width);

    const formattedData= useMemo(() => {
        if (!data) {
            return;
        }

        return getFormattedData(data);
    }, [data]);

    const columnWidth = (width/formattedData?.length)*BAR_RATIO-(domainPadding/2*BAR_RATIO);
    const selectionContainerProps = useSelectionTooltipContainer({
        lineWidth: columnWidth,
        data: formattedData,
        tooltipTitle: (selectedValue) => {
            const quarter = moment(selectedValue?.title).quarter();
            const year = moment(selectedValue?.title).year();

            return `${quarter} квартал ${year}, ${QUARTERS_MAP[quarter]}`;
        },
        tooltipContent: selectedValue => {
            return [
                {
                    title: 'Кредитный портфель',
                    value: `${selectedValue.y} млрд`,
                },
            ];
        },
    });

    return (
        <div className={styles['section']}>
            <SectionTitle
                tag="h2"
                mobileTitle={section.title}
                title={section.title}
            />
            <div className={styles['container']} ref={containerRef}>
                <VictoryChart
                    width={width}
                    height={height}
                    padding={getChartPadding(width)}
                    domainPadding={{ x: domainPadding }}
                    containerComponent={(
                        <VictoryCursorContainer {...selectionContainerProps} />
                    )}
                >
                    {/* Ось X */}
                    <VictoryAxis
                        tickValues={getXAxisTickValues(formattedData)}
                        style={{
                            axis: {
                                stroke: '#00000096',
                            },
                        }}
                        fixLabelOverlap
                        tickLabelComponent={<InvestmentsCreditPortfolioChartAxisTick data={formattedData} width={width} />}
                    />
                    <VictoryAxis
                        tickValues={['']}
                        axisComponent={<YearsAxis data={formattedData} />}
                    />
                    {/* Ось Y */}
                    <VictoryAxis
                        style={{
                            axis: { stroke: 'transparent' },
                            grid: {
                                stroke: ({ tickValue }: any) => tickValue === 0 ? 'transparent' : '#00000047',
                                strokeDasharray: '4',
                            },
                        }}
                        dependentAxis
                        tickValues={getYAxisValues(data).values}
                        tickLabelComponent={<InvestmentsCreditPortfolioChartAxisTick dependentAxis width={width} />}
                    />
                    {/* Группировка колонок */}
                    <VictoryBar
                        cornerRadius={{ top: getBarCornerRadius(width) }}
                        data={formattedData}
                        style={{
                            data: { fill: ({ datum }) => datum.color },
                        }}
                        barRatio={BAR_RATIO}
                    />
                </VictoryChart>
            </div>
        </div>
    );
};
