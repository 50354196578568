/**
 * Функция получения цвета из палитры по id аккаунта
 * @param accountId - идентификатор аккаунта, для которого необходимо выбрать цвет
 * @param colors - массив строк, содержащий HEX-коды цветов в палитре
 * @param defaultColor - дефолтный цвет
 * @returns Возвращаем цвет по остатку от деления или дефолтный цвет, если не получилось получить цвет из палитры
 *
 *  @category Utils
 *
 * @example
 *
 * getUserColorById(id, ['#fff', '#000]); // '#fff'
 *
 */
export function getUserColorById(accountId: number, colors: string[], defaultColor = '#5fa9ff'): string {
    if (!Array.isArray(colors) || colors.length === 0) {
        return defaultColor;
    }

    const index = accountId % colors.length;

    if (colors[index]) {
        return colors[index];
    }

    return defaultColor;
}