/* eslint-disable react/sort-comp */
import React from 'react';
import ReactModal from 'react-modal';
import closeIcon from '@frontend/jetlend-assets/icons/icon--close.svg';
import {
    releaseViewportHandler,
    requireViewportHandler,
} from '@frontend/jetlend-core/src/utils/viewportHandler';
import {
    addOutsideClickElement,
    removeOutputClickElement,
} from '@ui/utils/outsideClickManager';
import {
    buildClassNames,
    mergeClassNames,
} from '../../utils/classNameUtils';
import Responsive from '../../utils/responsive';
import { stopPropagation } from '../../utils/stopPropagation';
import {
    IModalCommonProps,
    IModalLayoutProps,
    IModalPresentationProps,
} from './common';
import LoaderBlock from '../loaders/LoaderBlock';
import IconButton from '../inputs/IconButton/IconButton';
import { UseFixedHeader } from '../FixedHeaderContext';

import styles from './modal.module.scss';
import ClientPortal from '../ClientPortal/ClientPortal';

const TRANSITION_DURATION_MS = 225;

export interface ModalProps extends IModalCommonProps, IModalLayoutProps, IModalPresentationProps {
    closable?: boolean;
    noBorder?: boolean;

    onSubmit?: (e: React.FormEvent) => void;
    contentClassName?: string;
}

export default class Modal extends React.Component<ModalProps> {
    private inlineStyles: ReactModal.Styles;

    private classes: ReactModal.Classes;

    private overlayClasses: ReactModal.Classes;

    private modalInlineStyles: React.CSSProperties;

    private contentContainerRef: React.RefObject<HTMLDivElement>;

    private prevContentContainerElement: HTMLDivElement;

    private prevOverlayElement: HTMLDivElement = null;

    constructor(props: ModalProps) {
        super(props);

        this.contentContainerRef = React.createRef<HTMLDivElement>();

        this.recompute();

        this.handleContentScroll = this.handleContentScroll.bind(this);
    }

    componentDidMount() {
        requireViewportHandler();

        setTimeout(() => {
            if (this.contentContainerRef.current) {
                this.prevContentContainerElement = this.contentContainerRef.current;

                this.prevContentContainerElement.addEventListener('scroll', this.handleContentScroll);
                window.addEventListener('scroll', this.handleContentScroll);
                window.addEventListener('resize', this.handleContentScroll);
            } else if (this.prevContentContainerElement) {
                this.prevContentContainerElement.removeEventListener('scroll', this.handleContentScroll);
                window.removeEventListener('scroll', this.handleContentScroll);
                window.removeEventListener('resize', this.handleContentScroll);
            }
        }, 0);
    }

    componentWillUnmount() {
        releaseViewportHandler();

        window.removeEventListener('keydown', this.close);

        if (this.prevContentContainerElement) {
            this.prevContentContainerElement.removeEventListener('scroll', this.handleContentScroll);
            window.removeEventListener('scroll', this.handleContentScroll);
            window.removeEventListener('resize', this.handleContentScroll);
        }
    }

    private handleContentScroll() {
        const contentElement = this.contentContainerRef.current;
        if (contentElement) {
            const scrollOffset = -contentElement.scrollTop;

            const dadataPlaceholderElements = contentElement.querySelectorAll<HTMLElement>('.suggestions-suggestions');
            Array.from(dadataPlaceholderElements).forEach(dadataPlaceholderElement => {
                dadataPlaceholderElement.style.transform = `translate(0px, ${scrollOffset.toFixed(0)}px)`;
            });

            const scrollingPortalElements = contentElement.querySelectorAll<HTMLElement>('[data-role="scrolling-host"]');
            Array.from(scrollingPortalElements).forEach(element => {
                const id = element.dataset['scrollingId'];
                const targetElement = document.querySelector<HTMLElement>(`[data-role="scrolling-portal"][data-scrolling-id="${id}"]`);
                if (targetElement) {
                    targetElement.style.transform = `translate(0px, ${scrollOffset.toFixed(0)}px)`;
                    targetElement.style.zIndex = (this.props.zIndex ?? 2010).toString();
                }
            });
        }
    }

    private close = (ev: KeyboardEvent) => {
        if (ev.key === 'Escape'){
            this.didClosed();
        }
    };

    private recompute() {
        this.inlineStyles = {
            overlay: {
                zIndex: this.props.zIndex || 2010,
            },
        };

        this.classes = {
            base: buildClassNames(styles, [
                'container',
                `container--${this.props.size}`,
                this.props.squeezeOnMobile && 'container--squeezed-mobile',
            ]),
            beforeClose: styles['container--before-close'],
            afterOpen: styles['container--after-open'],
        };

        this.overlayClasses = {
            base: styles['overlay'],
            beforeClose: styles['overlay--before-close'],
            afterOpen: styles['overlay--after-open'],
        };

        this.modalInlineStyles = this.props.minHeight > 0
            ? { height: this.props.minHeight + 300 }
            : {};
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    public getContentContainerElement(): HTMLDivElement {
        return this.contentContainerRef.current;
    }

    private didOverlayElementChanged(overlayElement: HTMLDivElement) {
        if (this.prevOverlayElement) {
            removeOutputClickElement(this.prevOverlayElement);
        }

        if (overlayElement) {
            addOutsideClickElement(overlayElement);
        }

        this.prevOverlayElement = overlayElement;
    }

    componentDidUpdate(prevProps: ModalProps) {
        if (this.props.isOpen !== prevProps.isOpen) {
            // Emulate transition events for contentContainerRef to able subscribe and correct handle it in inner components together with FixedHeaderContext
            this.contentContainerRef.current && this.contentContainerRef.current.dispatchEvent(new Event('transitionstart'));
            setTimeout(() => {
                this.contentContainerRef.current && this.contentContainerRef.current.dispatchEvent(new Event('transitionend'));
            }, TRANSITION_DURATION_MS);

            if (this.props.isOpen) {
                window.addEventListener('keydown', this.close);
            } else {
                window.removeEventListener('keydown', this.close);
            }
        }

        if (prevProps.zIndex !== this.props.zIndex ||
            prevProps.size !== this.props.size ||
            prevProps.minHeight !== this.props.minHeight
        ) {
            this.recompute();
        }

        setTimeout(() => {
            // Handle this changes when all DOM elements created on document

            if (this.contentContainerRef.current) {
                this.prevContentContainerElement = this.contentContainerRef.current;

                this.prevContentContainerElement.addEventListener('scroll', this.handleContentScroll);
                window.addEventListener('scroll', this.handleContentScroll);
                window.addEventListener('resize', this.handleContentScroll);
            } else if (this.prevContentContainerElement) {
                this.prevContentContainerElement.removeEventListener('scroll', this.handleContentScroll);
                window.removeEventListener('scroll', this.handleContentScroll);
                window.removeEventListener('resize', this.handleContentScroll);
            }
        }, 0);
    }

    private didClosed = () => {
        const { onClosed } = this.props;

        onClosed && onClosed();
    };

    render() {
        const {
            isOpen,
            loading,
        } = this.props;

        const {
            title,
            children,
            closable,
            header,
            footer,
            onSubmit,
            preventScroll,
        } = this.props;

        const emptyHeaderContent = !title && !header;

        const wrapperClassName = mergeClassNames([
            buildClassNames(styles, [
                'wrapper',
                this.props.squeezeOnMobile && 'wrapper--squeezed-mobile',
            ]),
            this.props.wrapperClassName,
        ]);

        const headerClassName = mergeClassNames([
            buildClassNames(styles, [
                'header',
                emptyHeaderContent && 'header--empty',
                (this.props.noBorder || emptyHeaderContent) && 'header--reduced-space',
                (!this.props.noBorder && !emptyHeaderContent) && 'header--border',
            ]),
            this.props.headerClassName,
        ]);

        const closeButtonClassName = mergeClassNames([
            styles['header__close-button-wrapper'],
            this.props.closeButtonClassName,
        ]);

        const contentClassName = mergeClassNames([
            buildClassNames(styles, [
                'content',
                emptyHeaderContent && 'content--empty',
            ]),
            this.props.contentClassName,
        ]);

        return (
            <ClientPortal>
                <div onMouseDown={stopPropagation} onClick={stopPropagation}>
                    <ReactModal
                        isOpen={isOpen === true}
                        closeTimeoutMS={TRANSITION_DURATION_MS}
                        // onAfterClose={this.didClosed}
                        onRequestClose={this.didClosed}
                        shouldCloseOnEsc={false}
                        shouldCloseOnOverlayClick={closable}
                        // TODO При переписывании на функциональный компонент замени на useScrollDisable
                        bodyOpenClassName={styles['body-overflow']}
                        className={this.classes}
                        overlayClassName={this.overlayClasses}
                        overlayRef={element => this.didOverlayElementChanged(element)}
                        style={this.inlineStyles}
                        preventScroll={preventScroll}
                    >
                        <form className={styles['form-wrapper']} onSubmit={onSubmit} data-testid={this.props.testId}>
                            <section className={wrapperClassName} style={this.modalInlineStyles}>
                                <header className={headerClassName}>
                                    <div className={styles['header--primary']}>
                                        {typeof title === 'string'
                                            ? (
                                                <h1 className={styles['header__title']}>
                                                    {title}
                                                </h1>
                                            )
                                            : (
                                                <div className={styles['header__title']}>
                                                    {title}
                                                </div>
                                            )}
                                        {header && (
                                            // eslint-disable-next-line react/jsx-no-useless-fragment
                                            <Responsive mobile={<></>}>
                                                <div className={styles['header__custom']}>{header}</div>
                                            </Responsive>
                                        )}

                                        {closable && (
                                            <div className={closeButtonClassName}>
                                                <IconButton
                                                    icon={closeIcon}
                                                    onClick={this.didClosed}
                                                    testId="modal-close-button"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {header && (
                                        // eslint-disable-next-line react/jsx-no-useless-fragment
                                        <Responsive notMobile={<></>}>
                                            <div className={styles['header--mobile']}>
                                                <div className={styles['header__custom']}>{header}</div>
                                            </div>
                                        </Responsive>
                                    )}
                                </header>
                                <div ref={this.contentContainerRef} className={contentClassName}>
                                    <UseFixedHeader height={77} scrollContainerRef={this.contentContainerRef}>
                                        {loading
                                            ? <LoaderBlock size="xlarge" />
                                            : children
                                        }
                                    </UseFixedHeader>
                                </div>

                                {footer && (
                                    <footer className={buildClassNames(styles, ['footer', !this.props.noBorder && 'footer--border'])}>
                                        {footer}
                                    </footer>
                                )}
                            </section>
                        </form>
                    </ReactModal>
                </div>
            </ClientPortal>
        );
    }
}
