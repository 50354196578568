import { IApiListQueryProps } from '@core/ducks/api';
import { sleep } from '@core/utils/sleep';

/**
 * Определяет, что код запущен в тестовом или демо режиме и необходимо возвращать Mock данные.
 *
 * @category Services
 *
 * @example
 * if (isMockApi()) {
 *     return testResponse(mockData);
 * }
 *
 * return apiGetRequest('/api/path');
 */
export function isMockApi(): boolean {
    return process.env.TARGET_ENV === 'test' || process.env.TARGET_ENV === 'demo';
}

export async function testResponse<TData>(data: TData, key = 'data', delay = 300): Promise<any> {
    await sleep(delay + 100 * Math.random());

    return {
        status: 'OK',
        [ key ]: data,
    };
}

export async function testPostResponse<TData = any>(data: TData, key = 'data', delay = 300) {
    await sleep(delay);
    return {
        status: 'OK',
        [key]: data,
    };
}

export async function testEmptyResponse(delay = 300) {
    return testPostResponse({}, 'data', delay);
}

export async function testErrorResponse(description: string, status = 'error', delay = 300) {
    await sleep(delay);

    return {
        status,
        error: description,
    };
}

export async function testBadResponse(status: number, data: any = null, delay = 300) {
    await sleep(delay);

    const e: any = new Error('Response error');
    e.response = {
        status,
        data,
    };

    throw e;
}

export async function testListApiResponse(data, query: IApiListQueryProps, delay = 300) {
    if (!query) {
        await sleep(delay);

        return {
            status: 'OK',
            total: data.length,
            data,
        };
    }

    const comparer = (a, b) => {

        const {
            field,
            dir,
        } = query.sort;

        const property = () => {
            if (field === 'company') {
                return 'loan_name';
            }
            if (field === 'status') {
                return 'progress';
            }
            if (field === 'end_date') {
                return 'term';
            }
            return field;
        };

        const getUTCMs = element => Date.parse(element.next_payment_date);

        const numEl = element => field === 'next_payment_date' ? getUTCMs(element) : element[property()];

        type comparerOperand = number | string | null

        const getComparer = (a: comparerOperand, b: comparerOperand, opposite = false, conditions: Array<boolean | string> = []) => {
            if ((a > b) || conditions[0]) {
                if (opposite) {
                    return dir === 'asc' ? -1 : 1;
                }
                return dir === 'asc' ? 1 : -1;
            }
            if ((b > a) || conditions[1]) {
                if (opposite) {
                    return dir === 'asc' ? 1 : -1;
                }
                return dir === 'asc' ? -1 : 1;
            }
            return 0;
        };
        // sort rating
        if (field === 'rating') {
            if (!a.rating || !b.rating) {
                const conditions = [(a.rating && !b.rating), (!a.rating && b.rating)];
                return getComparer(null, null, true, conditions);
            }
            // 'asc' order for ratings:  С -> C+ -> СС -> CC+ -> ССС -> CCC+ -> B -> B+ -> BB -> BB+ -> BBB -> BBB+ -> A -> A+ -> AA -> AA+ -> AAA -> AAA+
            // 'desc' order for ratings: AAA+ -> AAA -> AA+ -> AA -> A+ -> A -> +BBB -> BBB -> BB+ -> BB -> B+ -> B -> CCC+ -> ССС -> CC+ -> СС -> C+ -> С
            if (a.rating[0] === b.rating[0]) {
                if (a.rating.length === b.rating.length) {
                    const conditions = [(a.rating.includes('+')), (b.rating.includes('+'))];
                    return getComparer(null, null, true, conditions);
                }
                return getComparer(a.rating.length, b.rating.length);
            }
            return getComparer(a.rating, b.rating, true);
        }
        // sort status
        if (field === 'status') {
            // contracts with status 'default' placed first in 'asc' order and last in 'desc' order
            if (((a.status === 'default') && (b.status === 'default')) || ((a.status !== 'default') && (b.status !== 'default'))) {
                return getComparer(numEl(a), numEl(b), true);
            }
            const conditions = [(a.status === 'default'), (b.status === 'default')];
            return getComparer(null, null, true, conditions);
        }
        // sort numeric or loan_name values
        return getComparer(numEl(a), numEl(b));
    };

    if (query.sort) {
        data.sort(comparer);
    }

    const filteredData = query
        ? data.filter(item => {
            if (query.search) {
                if (!item.loan_name?.toLowerCase()?.includes(query.search.toLowerCase()) &&
                !item.company?.toLowerCase()?.includes(query.search.toLowerCase()) &&
                !item.title?.toLowerCase()?.includes(query.search.toLowerCase())
                ) {
                    return false;
                }
            }

            if (query.filter) {
                const accepted = query.filter.every(filter => {
                    const value = item[filter.field];

                    if (filter.values && Array.isArray(filter.values)) {
                        return filter.values.includes(value);
                    }

                    return true;
                });

                if (!accepted) {
                    return false;
                }
            }

            return true;
        })
        : data;

    const aggregation = query?.aggregation && query?.aggregation?.length > 0
        ? {}
        : undefined;

    if (query?.aggregation && query?.aggregation?.length > 0) {
        query.aggregation.forEach(aggregationItem => {
            aggregation[aggregationItem.field] = filteredData.reduce((acc, item) => acc + Number.parseFloat(item[aggregationItem.field]), 0);
        });
    }

    const offset = query.paging?.offset || 0;
    const limit = query.paging?.limit || 20;

    await sleep(delay);
    return {
        status: 'OK',
        total: filteredData.length,
        aggregation,
        data: query
            ? filteredData.slice(offset, offset + limit)
            : filteredData,
    };
}