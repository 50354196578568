import { simpleApiHandler } from '@frontend/jetlend-core/src/ducks/api/simple';
import {
    apiGetBusinessAreasChart,
    apiGetCompanyRevenueByYears,
    apiGetCreditPortfolioChart,
    apiGetMarketShareChart,
    apiGetMarketVolumeForecastByYears,
} from '@app/services/client/investments/investmentsService';
import {
    IChartDataApiModel,
    ICompanyYearRevenueApiModel,
    IMarketYearVolumeForecastApiModel,
} from '@app/models/investments/investments';

export const VERSION = 2;
export const PREFIX = 'investments';

export const creditPortfolioChartHandler = simpleApiHandler(PREFIX, 'credit-portfolio-chart', apiGetCreditPortfolioChart);

export const marketShareChartHandler = simpleApiHandler<IChartDataApiModel>(PREFIX, 'market-share-chart', apiGetMarketShareChart);

export const businessAreasChartHandler = simpleApiHandler<IChartDataApiModel>(PREFIX, 'market-business-areas', apiGetBusinessAreasChart);

export const companyRevenueByYearsHandler = simpleApiHandler<ICompanyYearRevenueApiModel[]>(PREFIX, 'company-revenue-by-years', apiGetCompanyRevenueByYears);

export const marketVolumeForecastByYearsHandler = simpleApiHandler<IMarketYearVolumeForecastApiModel[]>(PREFIX, 'market-volume-forecast', apiGetMarketVolumeForecastByYears);