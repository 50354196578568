import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import styles from './StepsSectionStepsCard.module.scss';
import SectionPart from '@app/components/server/ui/SectionPart/SectionPart';
import { IBaseSectionWithStepsModel } from '@app/models/common/common';

export default function StepsSectionStepsCard({ steps }: IBaseSectionWithStepsModel) {
    return (
        <SectionPart className={styles['card']}>
            {steps?.map((step, id) => {
                const stepClassNames = buildClassNames(styles, [
                    'step',
                    `step__${step.type}`,
                ]);

                return (
                    <div key={step?.title} className={stepClassNames}>
                        <div className={styles['id']}>
                            {id + 1}
                        </div>
                        <div className={styles['text']}>
                            {step?.title}
                        </div>
                    </div>
                );
            })}
        </SectionPart>
    );
};
