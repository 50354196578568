import { IInvestmentCalculatorChartPoint } from '@frontend/jetlend-core/src/features/AutoInvestCalculator/useAutoInvestCalculatorForecast';
import {
    BaseSectionWithBlocks,
    IBaseSectionApiModel,
} from '@app/models/common/common';

export enum InvestorsSectionType {
    FEATURES = 'features',
    CALCULATOR = 'calculator',
    HOW_IT_WORKS = 'how_it_works',
    GEOGRAPHY = 'geography',
    COMPANIES = 'companies',
    CHART = 'chart',
    CONDITIONS = 'conditions',
    FAQ = 'faq',
    MARKUP = 'markup',
}

export interface IInvestorsSectionBlockApiModel {
    title: string;
    value?: string;
    description?: string;
    image?: string;
}

export type InvestorsFeaturesSectionApiModel = BaseSectionWithBlocks<IInvestorsFeatureBlockApiModel>

/**
 * Тип для секции со списком компаний получивших займ
 */
export type InvestorsCompaniesSectionApiModel = IBaseSectionApiModel

export interface IInvestorsFeatureBlockApiModel extends IInvestorsSectionBlockApiModel {
    id: IInvestorsFeatureType;
}

export enum IInvestorsFeatureType {
    PROFITABILITY = 'profitability',
    RELIABILITY = 'reliability',
    LIQUIDITY = 'liquidity',
}

export enum InvestorsStatisticsType {
    DEFAULT = 'default',
    TOP = 'top',
}

export interface IInvestorsStatisticBlockApiModel extends IInvestorsSectionBlockApiModel {
    /**
     * Тип блока "Статистика"
     */
    type: InvestorsStatisticsType;
    /**
     * Ссылка для значения
     */
    value_url?: string;
}

export type InvestorsStatisticsSectionApiModel = BaseSectionWithBlocks<IInvestorsStatisticBlockApiModel>

export enum InvestorsIndicatorsType {
    DEFAULT = 'default',
    LARGE = 'large',
}

export interface IInvestorsIndicatorsBlockApiModel extends IInvestorsSectionBlockApiModel {
    type: InvestorsIndicatorsType;
}

export type InvestorsIndicatorsSectionApiModel = BaseSectionWithBlocks<IInvestorsIndicatorsBlockApiModel>

export interface IInvestorsGeographySectionApiModel extends IBaseSectionApiModel {
    image: string;
    regions: IInvestorsGeographyRegionApiModel[];
}

export interface IInvestorsGeographyRegionApiModel {
    title: string;
    part: string;
}

export interface IInvestorsChartSectionApiModel extends IBaseSectionApiModel {
    id: InvestorsChartType;
    header_description?: string;
    description?: string;
    events: IInvestorsChartEventApiModel[];
}

export enum InvestorsChartType {
    NPL_15 = 'npl15',
}

export interface IInvestorsChartEventApiModel {
    title: string;
    date: string;
}

export interface IConditionItemApiModel {
    title: string;
    description: string;
}

export interface IConditionsSectionApiModel extends IBaseSectionApiModel {
    items: IConditionItemApiModel[];
}

export interface IInvestmentCalculatorFormValues {
    initialAmount: number;
    monthlyTopUpAmount: number;
    termInYears: number;
}

export interface IInvestmentCalculatorResult {
    /**
     * Минимальный размер ожидаемого капитала
     */
    minExpectedAmount: number;
    /**
     * Максимальный размер ожидаемого капитала
     */
    maxExpectedAmount: number;
    /**
     * Минимальная ожидаемая доходность
     */
    minExpectedYieldRate: number;
    /**
     * Максимальная ожидаемая доходность
     */
    maxExpectedYieldRate: number;
     /**
     * Минимальный доход инвестора
     */
    minIncome: number;
     /**
     * Максимальный доход инвестора
     */
    maxIncome: number;
     /**
     * Срок инвестиций
     */
    termInYears?: number;
    /**
     * Данные для графика "ожидаемая доходность Jetlend"
     */
    jetlendChart: IInvestmentCalculatorChartPoint[];
     /**
     * Данные для графика "ожидаемая доходность акций"
     */
    stocksChart: IInvestmentCalculatorChartPoint[];
     /**
     * Данные для графика "ожидаемая доходность облигаций"
     */
    bondsChart: IInvestmentCalculatorChartPoint[];
     /**
     * Данные для графика "ожидаемая доходность по депозиту"
     */
    depositsChart: IInvestmentCalculatorChartPoint[];
     /**
     * Данные для графика "ожидаемая доходность по жилой недвижимости"
     */
    realEstatesChart: IInvestmentCalculatorChartPoint[];
}

export interface IInvestorsNplChartPointApiModel  {
    date: string;
    npl_1: number;
    npl_15: number;
    default: number;
}

export interface IInvestorsReliabilityPointApiModel {
    min_interest_rate: number;
    max_interest_rate: number;
    investors_part: number;
    investors_count: number;
}

/**
 * Описание модели для стакана вторичного рынка.
 */
export interface ILiquidityDomRecord {
    /**
     * Доходность к погашению.
     */
    ytm: number;
    /**
     * Количество заявок.
     */
    count: number;
    /**
     * Цена заявок на продажу.
     */
    price: number;
}

export interface IInvestorsJetLendStatisticsApiModel {
    min_interest_rate: number;
    max_interest_rate: number;

    /**
     * Средняя скорость сбора займа за последние 30 дней
     */
    average_duration: number;
    /**
     * Доля на рынке краудлендинга
     */
    platform_market_rate: number;
    /**
     * Средневзвешенная ставка за последние 12 месяцев
     */
    average_interest_rate: number;
    /**
     * Средневзвешенная ставка за последние 30 дней
     */
    average_interest_rate_30days: number;
    /**
     * Доля дефолтов за последние 12 месяцев
     */
    default_rate: number;
    /**
     * Доля дефолтов за все время
     */
    default_rate_all: number;
    /**
     * Кол-во инвесторов
     */
    investors_count: number;
    /**
     * Сумма выданных займов
     */
    loans_amount: number;
    /**
     * Кол-во заемщиков
     */
    success_borrowers_count: number;
    /**
     * Кол-во активных инвесторов
     */
    active_investors_count: number;
    /**
     * Кол-во зарегистрированных заемщиков
     */
    borrowers_count: number;
    /**
     * Средневзвешенная ставка на вторичном рынке
     */
    average_market_interest_rate: number;
    /**
     * объем сделок на вторичном рынке за последние 30 дней
     */
    market_volume_30days: number;
    /**
     * объем сделок на вторичном рынке за N-месяцев
     */
    market_volume_month: number;
    /**
     * объем сделок на вторичном рынке за все время
     */
    available_investing_amount: number;
    /**
     * Средне-взвешенная ставка по займам на сборке
     */
    waitings_interest_rate: number;

    inflation_rate: number;
    /**
     * Процент инвесторов, имеющие годовую доходность более уровня инфляции
     */
    investors_better_then_inflation: number;
    /**
     * Данные о скорости продажи заявок на вторичном рынке.
     */
    market_liquidity_speed: IInvestorsMarketLiquiditySpeed[];
    /**
     * Цена для расчета ликвидности
     */
    market_liquidity_price: number;
    /**
     * Список заявок на вторичном рынке для отображения примерного стакана.
     */
    order_book: ILiquidityDomRecord[];
    /**
     * Дефолтность предыдущего поколения
     */
    last_period_default_rate: number;
    /**
     * Год предыдущего поколения
     */
    last_period_default: number;
    /**
     * Процент инвесторов, имеющие годовую доходность более уровня депозита
     */
    deposit_rate: number;
    /**
     * Средняя доходность за последние 3 месяца
     */
    jl_on_strategy_rate_90_days: number;
    /**
     * Количество дней для расчета средней доходности инвесторов
     */
    jl_on_strategy_term: number;
    /**
     * Средней доходность инвесторов за указанный период
     */
    jl_on_strategy_rate: number;
    /**
     * Информация о размере стандартного welcome бонуса
     */
    welcome_cashback_rate: number;
    /**
     * Показатель акций
     */
    stocks_expected_interest_rate: number;
    /**
     * Показатель облигаций
     */
    bond_expected_interest_rate: number;
    /**
     * Показатель депозита
     */
    deposits_expected_interest_rate: number;
    /**
     *  Показатель доходности жилой недвижимости
     */
    real_estates_expected_interest_rate: number;
    /**
     * Период (количество месяцев) за который будет рассчитываться объем торгов
     */
    market_month_count?: number;
    /**
     * Среднегодовой темп роста компании в процентах
     */
    company_cagr?: number;
    /**
     * Срок тактической цели в годах
     */
    company_revenue_target_term?: number;
    /**
     * Степень увеличение выручки за указанный период достижения тактической цели
     */
    company_revenue_target_multiplier?: number;
}

export interface IInvestorsMarketLiquiditySpeed {
    /**
     * Объем сделок для продажи.
     */
    volume: 1000000 | 10000000;
    /**
     * Время продажи указанного объема в минутах.
     */
    time: number;
}

export enum InvestorsExternalIndicesChartIndexType {
    IMOEX = 'IMOEX',
    CBOND = 'CBOND',
    DEPOSIT = 'DEPOSIT',
    REAL_ESTATE = 'real_estate',
}
