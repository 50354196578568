import React, {
    useMemo,
    useRef,
} from 'react';
import {
    IResponsiveMenuButtonContext,
    ResponsiveMenuButtonContext,
} from './ResponsiveMenu.context';
import { useWindowResizeEffect } from '@ui/hooks/useWindowResizeEffect';

export interface IProps extends React.PropsWithChildren {
    /**
     * Порядковый индекс кнопки в меню.
     */
    index: number;
    /**
     * Должна ли кнопка быть скрыта в меню.
     */
    hidden: boolean;
    /**
     * Место отображения пункта меню.
     * @see {@link IResponsiveMenuButtonContext['placement']}
     */
    placement: IResponsiveMenuButtonContext['placement'];
    /**
     * @event
     * Событие, срабатывающее при изменении ширины кнопки.
     *
     * @param index Индекс кнопки в меню.
     * @param width Новая ширина кнопки.
     */
    onWidthChange?(index: number, width: number);
}

/**
 * Компонент обертка для кнопки меню.
 *
 * Создает необходимый контекст и замеряет ширину контента.
 * @internal
 */
export default function MenuButtonWrapper({
    index,
    hidden,
    placement,
    children,
    onWidthChange,
}: IProps) {
    const ref = useRef<HTMLDivElement>(null);

    // Используем ref для сохранения ширины кнопки
    const widthRef = useRef<number|null>(null);

    // Используем useWindowResizeEffect, чтобы обновить ширину кнопки при изменении размера окна
    useWindowResizeEffect(() => {
        const element = ref.current;
        if (!element || element.children.length === 0) {
            return;
        }

        // Чтобы получить ширину кнопки, нужно получить его первый дочерний элемент
        // т.к элемент обертки является виртуальным узлом и не имеет собственного размера.
        const buttonElement = element.children.item(0) as HTMLElement;

        const buttonElementWidth = Math.round(buttonElement.offsetWidth);

        // Вызываем событие только при изменении ширины
        if (widthRef.current === null || buttonElementWidth !== widthRef.current) {
            widthRef.current = buttonElementWidth;

            if (onWidthChange) {
                onWidthChange(index, buttonElementWidth);
            }
        }
    }, [ index, ref, widthRef, onWidthChange ]);

    const state = useMemo<IResponsiveMenuButtonContext>(() => ({
        index,
        hidden,
        placement,
    }), [ index, placement, hidden ]);

    return (
        <div ref={ref} className="h-contents">
            <ResponsiveMenuButtonContext.Provider value={state}>
                {children}
            </ResponsiveMenuButtonContext.Provider>
        </div>
    );
}