import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import ReactDOM from 'react-dom';
import clearIcon from '@frontend/jetlend-assets/icons/icon--close.svg';
import { IConnectableInputProps } from '@frontend/jetlend-core/src/ui/inputs/common';
import { connectToField } from '@frontend/jetlend-core/src/ui/inputs/connect';
import Loader from '../../loaders/Loader';

import styles from './searchInput.module.scss';
import { useWindowResizeEffect } from '@ui/hooks/useWindowResizeEffect';
import {
    buildClassNames,
    mergeClassNames,
} from '../../../utils/classNameUtils';
import IconButton from '../IconButton/IconButton';
import useScrollDisable from '@ui/hooks/useScrollDisable';

export type SearchInputSize = 'small'|'default';

export interface ISearchInputInstance {
    close: () => void;
}

export interface IProps extends IConnectableInputProps, React.PropsWithChildren {
    size?: SearchInputSize;
    placeholder?: string;
    loading?: boolean;
    className?: string;
    dropdownMenuClassName?: string;
    secondary?: boolean;
    autoFocus?: boolean;
    /**
     * Растянуть на ширину всего блока
     */
    wide?: boolean;

    instanceRef?: React.MutableRefObject<ISearchInputInstance>;

    onCancel?: () => void;
    onClear?: () => void;
}

const DROPDOWN_MIN_WIDTH = 120;

const SearchInput: React.FC<IProps> = props => {
    const placeholder = props.placeholder || 'Поиск';
    const containerRef = useRef<HTMLDivElement>();
    const inputRef = useRef<HTMLInputElement>();
    const [ dropdownPortalElement, setDropdownPortalElement ] = useState<HTMLDivElement|undefined>(undefined);
    const [ showResults, setShowResults ] = useState(false);
    const [ focus, setFocus ] = useState(false);

    useEffect(() => {
        if (props.instanceRef) {
            props.instanceRef.current = {
                close: () => setShowResults(false),
            };
        }
    }, [ props.instanceRef ]);

    const didFocused = useCallback(() => {
        setShowResults(true);
        setFocus(true);
        props.onFocus && props.onFocus(undefined);
    }, [ props.onFocus ]);

    const didBlurred = useCallback(() => {
        setFocus(false);
        props.onBlur && props.onBlur(undefined);
    }, [ props.onBlur ]);

    const didFadeClicked = useCallback(() => setShowResults(false), []);

    const didClear = useCallback(() => {
        props.onClear();
        setShowResults(false);
    }, [ props.onClear ]);

    const hasResults = !!props.children && React.Children.count(props.children) > 0;

    function getDropdownPosition() {
        // TODO Ensure that dropdown items have space a the bottom instead render it up
        // TODO Ensure that element position correct evaluates in Modal

        const element = containerRef.current;
        if (!element) {
            return {
                x: 0,
                y: 0,
                width: 0,
                maxHeight: undefined,
            };
        }

        const clientRect = element.getBoundingClientRect();
        const viewportWidth = document.body.getBoundingClientRect().width;
        const dropdownWidth = Math.min(Math.max(DROPDOWN_MIN_WIDTH, clientRect.width), viewportWidth);

        const x = (clientRect.x + window.scrollX) > viewportWidth - dropdownWidth
            ? Math.max(0, Math.min((clientRect.x + window.scrollX) - dropdownWidth + clientRect.width, viewportWidth - dropdownWidth))
            : Math.max(0, clientRect.x + window.scrollX);

        const y = (clientRect.y + window.scrollY) + clientRect.height;

        const maxHeight = Math.min(window.innerHeight - clientRect.y - clientRect.height - 15, window.innerHeight * 0.75);

        return {
            x,
            y,
            width: dropdownWidth,
            maxHeight,
        };
    }

    const [ dropdownPosition, setDropdownPosition ] = useState(getDropdownPosition());

    useLayoutEffect(() => {
        setDropdownPosition(getDropdownPosition());

        const portalElement = document.createElement('div');
        portalElement.classList.add(styles['dropdown-portal-container']);
        portalElement.style.position = 'absolute';
        setDropdownPortalElement(portalElement);
        document.body.append(portalElement);

        return () => {
            portalElement.remove();
            setDropdownPortalElement(undefined);
        };
    }, [ containerRef ]);

    useWindowResizeEffect(() => {
        setDropdownPosition(getDropdownPosition());
    }, [ containerRef ]);

    useEffect(() => {
        setDropdownPosition(getDropdownPosition());
    }, [ showResults, hasResults ]);

    useLayoutEffect(() => {
        if (!dropdownPortalElement) {
            return;
        }

        dropdownPortalElement.style.left = `${dropdownPosition.x}px`;
        dropdownPortalElement.style.top = `${dropdownPosition.y}px`;
    }, [ dropdownPosition, dropdownPortalElement ]);

    const shouldShowDropdown = hasResults && showResults;

    useScrollDisable(shouldShowDropdown);

    useEffect(() => {
        const keyboardHandler = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                setShowResults(false);
                props.onCancel && props.onCancel();
            }
        };

        if (shouldShowDropdown || focus) {
            document.addEventListener('keydown', keyboardHandler);

            return () => {
                document.removeEventListener('keydown', keyboardHandler);
            };
        }
    }, [ shouldShowDropdown, focus, props.onCancel ]);

    const containerClassName = mergeClassNames([
        buildClassNames(styles, [
            'container',
            shouldShowDropdown && 'container--active',
        ]),
        props.className,
    ]);

    const inputClassName = buildClassNames(styles, [
        'input',
        `input--size-${props.size ?? 'default'}`,
        props.wide && 'input--wide',
        props.secondary && 'input--secondary',
    ]);

    const iconClassName = buildClassNames(styles, [
        'icon',
        `icon--size-${props.size ?? 'default'}`,
    ]);

    const dropdownMenuClassName = mergeClassNames([
        styles['dropdown-menu'],
        props.dropdownMenuClassName,
    ]);

    return (
        <>
            <div ref={containerRef} className={containerClassName}>
                <input
                    ref={inputRef}
                    type="text"
                    placeholder={placeholder}
                    className={inputClassName}
                    value={props.value}
                    autoFocus={props.autoFocus}
                    onFocus={didFocused}
                    onChange={props.onChange}
                    onBlur={didBlurred}
                />
                <div className={iconClassName}>
                    {props.loading &&
                        <Loader size="normal" />
                    }
                    {!props.loading && !props.value && (
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z" fill="#C8CED2" />
                        </svg>
                    )}
                    {!props.loading && props.value && props.onClear &&
                        <IconButton icon={clearIcon} onClick={didClear} />
                    }
                </div>
            </div>

            <div
                className={buildClassNames(styles, [
                    'fade',
                    shouldShowDropdown && 'fade--active',
                ])}
                onClick={didFadeClicked}
            />

            {shouldShowDropdown && dropdownPortalElement && ReactDOM.createPortal((
                <div
                    className={dropdownMenuClassName}
                    style={{
                        width: dropdownPosition.width,
                        maxHeight: dropdownPosition.maxHeight,
                    }}
                >
                    {props.children}
                </div>
            ), dropdownPortalElement)}
        </>
    );
};

export const SearchInputField = connectToField(SearchInput);

export default SearchInput;