import React, { useCallback } from 'react';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import useStoreHandler from '@frontend/jetlend-core/src/hooks/useStoreHandler';
import {
    AutoInvestStrategyType,
    IAutoInvestStrategyState,
} from '@app/models/autoinvest';
import ChangeStrategyButton from '../ChangeStrategyButton/ChangeStrategyButton';
import {
    autoInvestStrategiesInfoHandler,
    currentStrategyHandler,
} from '@app/ducks/autoinvest';
import error from '@app/icons/icon--warning--red-v2.svg';
import baseStyles from '@app/components/client/investors/InvestmentCalculator/InvestmentCalculator.module.scss';

import styles from './strategySelector.module.scss';

const strategyIcons = {
    [AutoInvestStrategyType.Aggressive]: error,
};

/**
 * Компонент для отрисовки кнопок выбора стратегии в блоке калькулятора
 */
const StrategySelector = () => {
    const [{ selectedStrategyType: currentStrategy }, selectedStrategyTypeUpdate ] = useStoreHandler<IAutoInvestStrategyState>(currentStrategyHandler);
    const [strategies] = useSimpleApiHandler(autoInvestStrategiesInfoHandler);

    const didStrategyChanged = useCallback((selectedStrategyType: AutoInvestStrategyType) =>  {
        const strategiesItemData = strategies?.find(s => s.strategy === selectedStrategyType);

        selectedStrategyTypeUpdate({
            selectedStrategyType,
            strategiesItemData,
        });
    }, [strategies]);

    return (
        <>
            <div className={baseStyles['form-label']}>
                Стратегия
            </div>
            <div className={styles['container']}>
                {strategies && strategies.length > 0 && strategies.map(strategy => (
                    <ChangeStrategyButton
                        key={strategy.strategy}
                        className={buildClassNames(styles, [
                            'strategy-button',
                            strategy.strategy === currentStrategy && 'strategy-button--active',
                        ])}
                        textClassName={styles['strategy-button__text']}
                        strategy={strategy}
                        currentStrategy={currentStrategy}
                        onClick={didStrategyChanged}
                        icon={strategyIcons[strategy.strategy]}
                    />
                ))}
            </div>
        </>
    );
};

export default StrategySelector;
