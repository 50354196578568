import React, { useRef } from 'react';
import { IClassName } from '@frontend/jetlend-core/src/models/common';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import { useResizeObserver } from '@frontend/jetlend-web-ui/src/hooks/useResizeObserver';
import {
    isMobile,
    isTabletPortrait,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import styles from './CompanyRevenueTargetGoalChart.module.scss';

export interface IProps extends IClassName {
    title: string;
    description: string;
    multiplier: number;
}

function getChartRatioType(containerWidth: number, containerHeight: number, screenWidth: number): 'width'|'height' {
    const isTabletScreen = isTabletPortrait(screenWidth) || isMobile(screenWidth);

    if (isTabletScreen) {
        return containerWidth > containerHeight ? 'height' : 'width';
    }

    return containerWidth * 0.5 > containerHeight
        ? 'height'
        : 'width';
}

export default function CompanyRevenueTargetGoalChart({
    title,
    description,
    multiplier,
    className: externalClassName,
}: IProps) {
    const containerRef = useRef<HTMLDivElement>(null);
    const [ containerWidth, containerHeight ] = useResizeObserver(containerRef);

    const { width: screenWidth } = useWindowSize();


    const containerClassName = buildClassNames(styles, [
        'container',
        `ratio--by-${getChartRatioType(containerWidth, containerHeight, screenWidth)}`,
    ], [ externalClassName ]);

    return (
        <div ref={containerRef} className={containerClassName}>
            <div className={buildClassNames(styles, ['background', 'background--target'])} />
            <div className={buildClassNames(styles, ['background', 'background--current'])} />

            <div className={styles['content']}>
                <div className={styles['header']}>
                    <div className={styles['description']}>
                        {description}
                    </div>
                    <div className={styles['multiplier']}>
                        <small>x</small>
                        {multiplier}
                    </div>
                </div>
                <div className={styles['title']}>
                    {title}
                </div>
            </div>
        </div>
    );
}