import React from 'react';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import LoaderBlock from '@frontend/jetlend-web-ui/src/ui/loaders/LoaderBlock';
import { FormAlert } from '@frontend/jetlend-web-ui/src/ui/forms/FormAlert/FormAlert';
import useStoreHandler from '@frontend/jetlend-core/src/hooks/useStoreHandler';
import {
    autoInvestStrategiesInfoHandler,
    currentStrategyHandler,
    RATINGS_LEVEL,
} from '@app/ducks/autoinvest';
import StrategyExpectedYieldChart from './StrategyExpectedYieldChart';
import { IAutoInvestStrategyState } from '@app/models/autoinvest';

import styles from './strategyExpectedYieldChart.module.scss';

/**
 * Компонент для отрисовки информации о доходности и передачи данных графику
 */
const ConnectedStrategyExpectedYieldChart: React.FC = () => {
    const [strategies, isFetching] = useSimpleApiHandler(autoInvestStrategiesInfoHandler);
    const [{
        selectedStrategyType,
        strategiesItemData,
    }] = useStoreHandler<IAutoInvestStrategyState>(currentStrategyHandler);

    if (isFetching || !strategies) {
        return <LoaderBlock />;
    }

    return (
        <>
            <StrategyExpectedYieldChart data={strategiesItemData} />
            <FormAlert
                alert={<div className="h-text-bold">Инвестиции в займы с рейтингами {RATINGS_LEVEL[selectedStrategyType]}</div>}
                type="success"
            />
            <div className={styles['info']}>Доходность не гарантирована и может выходить за указанный диапазон.</div>
        </>
    );
};

export default ConnectedStrategyExpectedYieldChart;
