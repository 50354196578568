'use client';

import { ISectionBasedProps } from '@app/models/common/common';
import HistoryFeature from '@app/components/server/common/HistoryFeature/HistoryFeature';
import { getBlockType } from './InvestmentsFeaturesSection.logic';
import { InvestmentsFeaturesSectionApiModel } from '@app/models/investments/investments';
import InvestmentsBannersSection from '@app/components/server/investments/InvestmentsBannersSection/InvestmentsBannersSection';
import InvestmentsSharesStepsSection from '@app/components/server/investments/InvestmentsSharesStepsSection/InvestmentsSharesStepsSection';

export default function InvestmentsFeaturesSection(props: ISectionBasedProps<InvestmentsFeaturesSectionApiModel>) {
    const { section } = props;
    const sectionType = getBlockType(section.blocks[0]);

    const config = {
        'history': <HistoryFeature section={section} />,
        'banners': <InvestmentsBannersSection section={section} />,
        'shares': <InvestmentsSharesStepsSection section={section} />,
        'null': null,
    };

    return config[sectionType];
};
