'use client';

import React, {
    ReactNode,
    Suspense,
} from 'react';
import LoaderBlock from '@frontend/jetlend-web-ui/src/ui/loaders/LoaderBlock';
import { ISectionBasedProps } from '@app/models/common/common';
import OffscreenLoader from '@app/components/server/ui/OffscreenLoader/OffscreenLoader';
import {
    InvestmentsChartType,
    IChartApiModel,
} from '@app/models/investments/investments';
import InvestmentsMarketShareChart from '@app/components/client/investments/InvestmentsMarketShareChart/InvestmentsMarketShareChart';
import InvestmentsBusinessAreasChart from '@app/components/client/investments/InvestmentsBusinessAreasChart/InvestmentsBusinessAreasChart';
import InvestmentsCreditPortfolioChart from '@app/components/client/investments/InvestmentsCreditPortfolio/InvestmentsCreditPortfolioChart';
import InvestmentsCompanyRevenueChart from '../InvestmentsCompanyRevenueChart/InvestmentsCompanyRevenueChart';

/**
 * Типы графиков
 */
const CHARTS_MAP: Record<InvestmentsChartType, (section: IChartApiModel) => ReactNode> = ({
    [InvestmentsChartType.MARKET_SHARE]: section => (
        <InvestmentsMarketShareChart section={section} />
    ),
    [InvestmentsChartType.BUSINESS_AREAS]: section => (
        <InvestmentsBusinessAreasChart section={section} />
    ),
    [InvestmentsChartType.CREDIT_PORTFOLIO]: section => (
        <InvestmentsCreditPortfolioChart section={section} />
    ),
    [InvestmentsChartType.COMPANY_REVENUE]: section => (
        <InvestmentsCompanyRevenueChart section={section} />
    ),
});

/**
 * Секция для отрисовки различных графиков
 */
export default function ChartSection({ section }: ISectionBasedProps<IChartApiModel>) {
    return (
        <OffscreenLoader>
            {({
                inView,
                ref,
            }) => (
                <div ref={ref}>
                    <Suspense fallback={<LoaderBlock />}>
                        {inView && CHARTS_MAP[section.id]?.(section)}
                    </Suspense>
                </div>
            )}
        </OffscreenLoader>
    );
}

