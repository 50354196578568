import React from 'react';

/**
 * Функция принимает простую строку текста с разными спец символами и возвращает корректный JSX.
 *
 * Например, если в строке есть \n, то он переносит строку в помощью <br />.
 *
 * @category Utils
 */
export function renderMultilineContent(content: string): JSX.Element|React.ReactNode {
    if (!content) {
        return null;
    }

    // \n - unix line separator
    // \r\n - windows line separator
    // \u2028 - line separator
    const lines = content.split(/[\r\n\u2028]+/gm);

    return lines.map((line, index) => (
        <React.Fragment key={index}>
            {index > 0 && <br />}
            {line}
        </React.Fragment>
    ));
}
