import {
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { VictoryCommonPrimitiveProps } from 'victory';

export interface IProps {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    title?: React.ReactNode;
    titleHeight?: number;
    titlePadding?: number;
    radius?: number;
    offset?: number;
    midY: number;
}

const ARROW_COLOR = '#A5D2C7';

export default function ShapedArrow({
    x1,
    y1,
    x2,
    y2,
    midY,
    scale,
    title,
    fontSize = 14,
    titlePadding = 10,
    titleHeight = 28,
    radius = 12,
    offset = 0,
}: IProps & VictoryCommonPrimitiveProps) {
    const xStart = scale.x(x1);
    const xEnd = scale.x(x2);
    const yStart = scale.y(y1) - offset;
    const yEnd = scale.y(y2) - offset;

    const pathData = `
        M ${xStart} ${yStart}
        V ${midY + radius}
        A ${radius},${radius} 0 0 1 ${xStart + radius},${midY}
        H ${xStart + radius} ${xEnd - radius}
        A ${radius},${radius} 0 0 1 ${xEnd},${midY + radius}
        V ${yEnd - 7}
    `;

    const titleTextRef = useRef<SVGTextElement>();

    const [ titleWidth, setTitleWidth ] = useState(0);
    useLayoutEffect(() => {
        const titleElement = titleTextRef.current;

        if (!titleElement) {
            return;
        }

        const titleLength = titleElement.getComputedTextLength();
        setTitleWidth(Math.round(titleLength));
    }, [ title ]);

    const titleX = xStart + (xEnd - xStart) * 0.5 - titleWidth * 0.5 - titlePadding;
    const titleY = midY - titleHeight * 0.5;

    return (
        <>
            <path
                d={pathData}
                stroke={ARROW_COLOR}
                strokeWidth="1"
                fill="none"
                markerEnd="url(#arrowhead)"
            />
            {title && (
                <g>
                    <rect
                        x={titleX}
                        y={titleY}
                        width={titleWidth + titlePadding * 2}
                        height={titleHeight}
                        rx={titleHeight * 0.5}
                        ry={titleHeight * 0.5}
                        fill="#FFF"
                        stroke={ARROW_COLOR}
                        strokeWidth={1}
                    />
                    <text
                        x={titleX + titlePadding}
                        y={titleY + titleHeight * 0.5 + 5}
                        fontSize={14}
                        style={{
                            fontFamily: 'inherit',
                            color: '#1E2021',
                            fontWeight: 400,
                        }}
                        ref={titleTextRef}
                    >
                        {title}
                    </text>
                </g>
            )}
            <defs>
                <marker
                    id="arrowhead"
                    markerWidth="10"
                    markerHeight="7"
                    refX="0"
                    refY="3.5"
                    orient="auto"
                >
                    <polygon points="0 0, 10 3.5, 0 7" fill={ARROW_COLOR} />
                </marker>
            </defs>
        </>
    );
}