import { useMemo } from 'react';

export interface IYieldCalculatorResult {
    /**
     * Минимальный ожидаемый доход
     */
    minIncome: number;
    /**
     * Максимальный ожидаемый доход
     */
    maxIncome: number;
    /**
     * Минимальный ожидаемый размер капитала
     */
    minCapital: number;
    /**
     * Максимальный ожидаемый размер капитала
     */
    maxCapital: number;
}

export interface IInvestmentCalculatorChartPoint extends IYieldCalculatorResult {
    /**
     * Номер месяца
     */
    month: number;
    /**
     * Среднее значение размера капитала
     */
    capital: number;
    /**
     * Среднее значение ставки прибыли
     */
    profitRate: number;
    /**
     * Минимальная ставка прибыли
     */
    minProfitRate: number;
    /**
     * Максимальная ставка прибыли
     */
    maxProfitRate: number;
}

/**
 * Функция расчета ожидаемых показателей калькулятора
 *
 * @param initialInvestment Первоначальная сумма инвестиций
 * @param monthlyContribution Сумма ежемесячного пополнения
 * @param investmentPeriod Срок инвестиций
 * @param minYield Минимальная ставка
 * @param maxYield Максимальная ставка
 * @returns Ожидаемая доходность, доход и размер капитала (с/без реинвестирования)
 */
export function calculateInvestmentForecast (
    initialInvestment: number,
    monthlyContribution: number,
    investmentPeriod: number,
    minYield: number,
    maxYield: number,
    reinvest: boolean
): IYieldCalculatorResult & { chart: IInvestmentCalculatorChartPoint[] } {
    const minYieldMonth = minYield / 12;
    const maxYieldMonth = maxYield / 12;
    const totalMonths = investmentPeriod * 12;

    let previousBalanceMin = initialInvestment;
    let previousBalanceMax = initialInvestment;

    const interestMinArray = [];
    const interestMaxArray = [];

    const chartForecastArray: IInvestmentCalculatorChartPoint[] = [{
        month: 0,
        capital: initialInvestment,
        minCapital: initialInvestment,
        maxCapital: initialInvestment,
        minIncome: 0,
        maxIncome: 0,
        profitRate: 0,
        minProfitRate: 0,
        maxProfitRate: 0,
    }];

    for (let month = 1; month <= totalMonths; month++) {
        const sumInterestMin = interestMinArray.reduce((a, b) => a + b, 0);
        const sumInterestMax = interestMaxArray.reduce((a, b) => a + b, 0);

        // Вычисляем доходы для текущего месяца
        const interestMin = (reinvest ? previousBalanceMin : (previousBalanceMin - sumInterestMin)) * minYieldMonth;
        const interestMax = (reinvest ? previousBalanceMax : (previousBalanceMax - sumInterestMax)) * maxYieldMonth;

        interestMinArray.push(interestMin);
        interestMaxArray.push(interestMax);

        // Обновляем балансы
        previousBalanceMin += interestMin;  // добавляем доход к минимальному капиталу
        previousBalanceMax += interestMax;  // добавляем доход к максимальному капиталу

        // Добавляем взносы
        previousBalanceMin += monthlyContribution;
        previousBalanceMax += monthlyContribution;

        // Добавляем данные в массив для графика
        chartForecastArray.push({
            month,
            capital: (previousBalanceMin + previousBalanceMax) / 2,
            minCapital: previousBalanceMin,
            maxCapital: previousBalanceMax,
            minIncome: previousBalanceMin - (initialInvestment + monthlyContribution * month),
            maxIncome: previousBalanceMax - (initialInvestment + monthlyContribution * month),
            profitRate: (previousBalanceMin - initialInvestment) / initialInvestment,
            minProfitRate: (previousBalanceMin - initialInvestment) / initialInvestment,
            maxProfitRate: (previousBalanceMax - initialInvestment) / initialInvestment,
        });
    }

    return {
        minIncome: previousBalanceMin - (initialInvestment + monthlyContribution * totalMonths),
        maxIncome: previousBalanceMax - (initialInvestment + monthlyContribution * totalMonths),
        minCapital: previousBalanceMin,
        maxCapital: previousBalanceMax,
        chart: chartForecastArray,
    };
}

/**
 * Хук с логикой расчета ожидаемого дохода для инвестиционного калькулятора
 *
 * @param initial_amount Первоначальная сумма инвестиций
 * @param monthly_amount Сумма ежемесячного пополнения
 * @param investment_term Срок инвестиций
 * @param reinvest С/без реинвестирования
 * @param minYield Минимальная ставка
 * @param maxYield Максимальная ставка
 * @returns Результаты вычислений (ожидаемая доходность, доход и размер капитала)
 *
 * @category Hooks
 *
 * @example
 * const { expectedResults } = useAutoInvestCalculatorForecast(10000, 1000, 1, true, 0.29, 0.43);
 */
export function useAutoInvestCalculatorForecast(
    initial_amount: number,
    monthly_amount: number,
    investment_term: number,
    reinvest: boolean,
    minYield: number,
    maxYield: number
): IYieldCalculatorResult & { chart: IInvestmentCalculatorChartPoint[] } {
    const calculatorResult = useMemo(() => ({
        ...calculateInvestmentForecast(
            initial_amount,
            monthly_amount,
            investment_term,
            minYield,
            maxYield,
            reinvest
        ),
        minYield,
        maxYield,
    }), [
        initial_amount,
        monthly_amount,
        investment_term,
        reinvest,
        minYield,
        maxYield,
    ]);

    return {
        ...calculatorResult,
    };
}