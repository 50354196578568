/**
 * Функция для форматирования строки с заглавной буквой.
 * @param text Исходная строка.
 * @returns Отформатированная строка, где первая буква заглавная, а остальные маленькие.
 *
 * @category Formatters
 */
export default function capitalize(text: string): string {
    if (!text || text.length === 0) {
        return text;
    }

    if (text.length === 1) {
        return text.toUpperCase();
    }

    const prepared = text
        .trim()
        .toLowerCase();

    return prepared.substring(0, 1).toUpperCase() + prepared.substring(1);
}
