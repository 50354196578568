import React, { useMemo } from 'react';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import styles from './InvestmentsSharesStepsSection.module.scss';
import {
    BaseSectionWithBlocksAndDescription,
    IFeatureBlockApiModel,
    ISectionBasedProps,
} from '@app/models/common/common';
import { InvestmentsSharesStepsFeatureType } from '@app/models/investments/investments';
import InvestmentsSharesStepsBlock from '@app/components/server/investments/InvestmentsSharesStepsSection/InvestmentsSharesStepsBlock/InvestmentsSharesStepsBlock';

type Props = ISectionBasedProps<BaseSectionWithBlocksAndDescription<IFeatureBlockApiModel<InvestmentsSharesStepsFeatureType>>>

/**
 * Секция "Шаги инвестирования"
 */
export default function InvestmentsSharesStepsSection({ section }: Props) {

    const formattedBlocks = useMemo(() => {
        const blocks = section?.blocks;

        if (!blocks) {
            return [];
        }
        return [
            blocks[0],
            [blocks[1], blocks[2]],
            blocks[3],
        ];
    }, [section.blocks]);

    return (
        <div className={styles['section']}>
            <h2 className={styles['section__title']}>
                {section.title}
            </h2>
            <div className={styles['section__blocks']}>
                {formattedBlocks?.map((step, index) => {
                    return (
                        <div className={buildClassNames(styles, ['block', Array.isArray(step) && 'block__wide'])} key={index}>
                            <p className={styles['block__step']}>шаг {index + 1}</p>
                            {Array.isArray(step)
                                ? step.map((block, block_index) => (
                                    <>
                                        <InvestmentsSharesStepsBlock {...block} key={block.id} />
                                        {block_index + 1 < step.length && (
                                            <div className={styles['block__separator']}>
                                                <p className={styles['block__separator-text']}>или</p>
                                                <hr className={styles['block__separator-line']} />
                                            </div>
                                        )}
                                    </>
                                ))
                                : (
                                    <InvestmentsSharesStepsBlock {...step} />
                                )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}