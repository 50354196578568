import {
    IStoreHandler,
    IStoreHandlerBasicConfig,
    storeHandler,
} from '@frontend/jetlend-core/src/ducks/store';
import {
    loadLocalStorageState,
    saveLocalStorageState,
} from '@ui/core/localStorage';

/**
 * Хендлер для хранения данных в локальном хранилище браузера (localStorage).
 *
 * @param prefix Префикс duck модуля
 * @param name Имя хендлера внутри модуля
 * @param config Дополнительные настройки хендлера
 * @returns Новый экземпляр управления хранилищем данных
 *
 * @see {@link IStoreHandler}
 * @see {@link useStoreHandler}
 *
 * @category Duck Handlers
 *
 * @example
 * // ducks/settings.ts
 * const settings = localStoreHandler(PREFIX, 'settings');
 *
 * // Component.tsx
 * const [ settings, updateSettings ] = useStoreHandler(settings);
 */
export default function localStoreHandler<TModel = any>(prefix: string, name: string, config?: IStoreHandlerBasicConfig<TModel>): IStoreHandler<TModel> {
    const storeKey = `$$__${prefix}/${name}`;

    return storeHandler(prefix, name, {
        ...(config || {}),
        onLoad: () => loadLocalStorageState(storeKey),
        onSave: data => saveLocalStorageState(storeKey, data),
    });
}