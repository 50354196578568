export const DEFAULT_USER_COLOR = '#5FA9FF';

/**
 * Палитра цветов иконки пользователя
 */
export const USER_COLORS_PALETTE = [
    '#4096FF',
    '#00CC83',
    '#20836D',
    '#FA8C16',
    '#36CFC9',
    '#BAE637',
    '#FADB14',
    '#1677FF',
];