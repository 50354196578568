import React from 'react';
import { IClassName } from '@frontend/jetlend-core/src/models/common';
import styles from './responsiveMenu.module.scss';
import { buildClassNames } from '../../utils/classNameUtils';

export interface IProps extends IClassName {
    /**
     * Заголовок пункта меню.
     */
    title: string|React.ReactNode;
    /**
     * Ссылка пункта меню.
     */
    href: string;
    /**
     * Поведение ссылки для индексации.
     */
    rel?: string;
    /**
     * Активен ли пункт меню в данный момент или нет.
     */
    active?: boolean;
}

/**
 * Компонент обычной кнопки пункта меню.
 * Должно применяться совместно с компонентом {@link ResponsiveMenu}.
 */
export default function ResponsiveMenuButton({
    title,
    href,
    rel,
    active,
    className: externalClassName,
}: IProps) {
    const buttonClassName = buildClassNames(styles, [
        'button',
        active && 'button--active',
    ], [ externalClassName ]);

    return (
        <a className={buttonClassName} href={href} rel={rel}>
            {title}
        </a>
    );
};
