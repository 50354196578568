'use client';

import Tooltip from '@frontend/jetlend-web-ui/src/ui/TooltipIcon/Tooltip';
import { Slice } from 'victory';
import { useWindowSize } from '@frontend/jetlend-web-ui/src/utils/responsive';

export default function SliceWithTooltip(props) {
    const {
        datum,
        active,
    } = props;

    const { width } = useWindowSize();
    const isDesktop = width > 1024;

    return (
        <Tooltip title={datum.title} disableWrapper position="top" followCursor={isDesktop}>
            <g
                style={{
                    transition: '0.3s',
                    transform: `scale(${active ? 1.02 : 1})`,
                    transformOrigin: 'center',
                }}
            >
                <Slice {...props} />
            </g>
        </Tooltip>
    );
}