import React, {
    useCallback,
    useRef,
    useState,
} from 'react';
import { VictoryCursorContainerProps } from 'victory';
import { useEventCallback } from '@mui/material';
import SelectionTooltip from './SelectionTooltip';
import {
    ISelectionTooltipOptions,
    IValue,
} from './SelectionTooltip.models';

/**
 * Функция для получения выбранного значения
 */
function getSelectedItem<TValue>(data: TValue[]|Record<string, TValue[]>, xValue: number) {
    // Если переданные значения просто списком, то работаем стандартно
    if (Array.isArray(data)) {
        return data.find(item => (item as any).x === xValue);
    }

    // Если работа идет с больше, чем одним значением
    let result = {};

    // Получаем текущие значения, над которыми находится курсор
    const values = Object.values(data).map((value: any[]) => {
        return value.find(item => item.x === xValue);
    });

    if (values[0] === undefined) {
        // Если под курсором нет никаких значений, то отдаем null
        result = null;
    } else {
        // Форматируем значения в такие, чтобы BarChart мог их понять
        Object.keys(data).forEach((key, index) => {
            result[key] = values[index];
        });
    }

    return result;
}

/**
 * Хук для использования тултипа для графиков Victory
 *
 * @example
 * const selectionContainerProps = useSelectionTooltipContainer({
 *         lineWidth: columnWidth,
 *         data: formattedData,
 *         tooltipTitle: (selectedValue) => {
 *             const quarter = moment(selectedValue?.title).quarter();
 *             const year = moment(selectedValue?.title).year();
 *
 *             return `${quarter} квартал ${year}, ${QUARTERS_MAP[quarter]}`;
 *         },
 *         tooltipContent: selectedValue => {
 *             return [
 *                 {
 *                     title: 'Кредитный портфель',
 *                     value: `${selectedValue.y} млрд`,
 *                 },
 *             ];
 *         },
 *     })
 *
 * return (
 *     <VictoryChart
 *         containerComponent={(
 *             <VictoryCursorContainer {...selectionContainerProps} />
 *         )}
 *     />
 * )
 */
export function useSelectionTooltipContainer<TValue extends IValue>({
    lineWidth,
    data,
    ...props
}: ISelectionTooltipOptions<TValue>) {
    const [axisValues, setAxisValues] = useState(null);
    const [selectedValue, setSelectedValue] = useState<TValue | Record<string, TValue> | null>(null);
    const width = useRef(0);

    const didCursorMoved = useEventCallback(useCallback((value: number, cursorProps: VictoryCursorContainerProps) => {
        if (value === null || !data) {
            return;
        }

        const xValue = Math.round(value);
        const selectedItem: TValue | Record<string, TValue> = getSelectedItem(data, xValue);

        if (JSON.stringify(selectedItem) !== JSON.stringify(selectedValue)) {
            if (!selectedItem) {
                setSelectedValue(null);
            } else {
                // Данные по текущему элементу, над которым находится курсор
                setSelectedValue(selectedItem);
            }

            const firstKey = Object.keys(data)[0];
            const yValue = Array.isArray(data) ? selectedItem?.y : selectedItem?.[firstKey]?.y;

            /**
             * Получаем абсолютные значения в пикселях для оси X, чтобы корректно отобразить тултип
             *
             * Получаем значение по Y для корректного отображения тултипа на уровне с тем, где заканчивается элемент
             */
            setAxisValues({
                x: cursorProps.scale.x(xValue),
                y: cursorProps.scale.y(yValue as number),
            });
        }

        if (width.current !== cursorProps.width) {
            // Получаем ширину графика
            width.current = cursorProps.width;
        }
    }, [data, selectedValue]));

    return {
        onCursorChange: didCursorMoved,
        cursorComponent: (
            <SelectionTooltip
                axisValues={axisValues}
                lineWidth={lineWidth}
                selectedValue={selectedValue}
                width={width.current}
                {...props}
            />
        ),
        cursorDimension: 'x',
    } as const;
}
