import { simpleFormHandler } from '@frontend/jetlend-core/src/ducks/form';
import {
    put,
    select,
} from 'redux-saga/effects';
import { objectHandler } from '@frontend/jetlend-core/src/ducks/object';
import { simpleSagaHandler } from '@frontend/jetlend-core/src/ducks/saga';
import {
    isEmptyValue,
    requireEmail,
    requirePhone,
    required,
} from '@frontend/jetlend-core/src/validators';
import { modalHandler } from '@frontend/jetlend-core/src/ducks/modal';
import { ApiDataResponse } from '@frontend/jetlend-core/src/models/api';
import { isMobile } from 'mobile-device-detect';
import { addToastError } from '@frontend/jetlend-core/src/ducks/toasts';
import {
    IEmailFormValues,
    IPhoneRegistrationFormValues,
    IRegistrationFormValues,
    IRegistrationResultApiModel,
    IRegistrationState,
    RegistrationDeviceType,
} from '@app/models/common/registration';
import {
    apiPostPhoneRegistration,
    apiPostProfileEmailUpdate,
    apiPostUserRegistration,
} from '@app/services/client/common/registrationService';
import { signDataWithSmsSaga } from './sms';
import { sendEvent } from './analytics';
import {
    CLIENT_TYPE_CABINET_URLS,
    CLIENT_TYPE_REGISTRATION_URLS,
    ClientType,
    CommonStage,
} from '@app/models/common/common';
import { IExternalLoginValues } from '@app/models/common/oauth';
import {
    ILoginResultApiModel,
    LoginDeviceType,
} from '@app/models/common/login';
import { apiPostExternalUserLogin } from '@app/services/client/common/oauthService';
import { setRedirectUriFormValues } from './login';

export const VERSION = 2;
export const PREFIX = 'common_registration';

export const commonRegistrationStateHandler = objectHandler<IRegistrationState>(
    PREFIX, 'state'
);

type StartRegistrationSagaEvent = {
    value: {
        clientType: ClientType;
        phone?: string;
    };
};

export const startRegistrationSagaHandler = simpleSagaHandler(PREFIX, 'start', function* ({ value }: StartRegistrationSagaEvent) {
    const {
        clientType,
        phone,
    } = value;

    // Initialize state for first screen
    yield put(commonRegistrationStateHandler.update({
        clientType,
        phone,
        stage: CommonStage.Phone,
        email: undefined,
        redirectUri: undefined,
        userExists: undefined,
        loadingTitle: '',
    }));

    // Update form for initial values if that exists
    const formValues: IPhoneRegistrationFormValues = {
        clientType,
        phone,
    };

    yield put(commonPhoneRegistrationFormHandler.formInitialize(formValues));

    // Submit form when phone already provided
    const hasPhone = !isEmptyValue(phone);
    if (hasPhone) {
        yield put(commonPhoneRegistrationFormHandler.submit(formValues));
    }

    yield put(commonRegistrationModalHandler.open());
});

export const startRegistration = (clientType: ClientType, phone?: string) => startRegistrationSagaHandler.action({
    clientType,
    phone,
});

export const commonRegistrationModalHandler = modalHandler(PREFIX, 'modal', 'common_registration_modal');

export const changePhoneNumberSagaHandler = simpleSagaHandler(PREFIX, 'change_phone', function* () {
    yield put(commonRegistrationStateHandler.update({
        stage: CommonStage.Phone,
        userExist: undefined,
        isChangePhonePressed: true,
    }));
});

export const changeSmsSagaHandler = simpleSagaHandler(PREFIX, 'change_sms', function* () {
    yield put(commonRegistrationStateHandler.update({
        stage: CommonStage.Sms,
        userExist: undefined,
        isChangePhonePressed: true,
    }));
});

export const changePhoneNumber = () => changePhoneNumberSagaHandler.action();
export const changeSms = () => changeSmsSagaHandler.action();

export const commonPhoneRegistrationFormHandler = simpleFormHandler<IPhoneRegistrationFormValues>(
    PREFIX, 'phone_form', {
        v2: {
            phone: [ required(), requirePhone() ],
        },
    }, {
        apiMethod: apiPostPhoneRegistration,
        *onBeforeSubmit (values: IPhoneRegistrationFormValues) {
            if (values?.clientType) {
                yield put(sendEvent(`guest-${values?.clientType}--phone-input`));

                if (values?.clientType === ClientType.Investor) {
                    yield put(sendEvent('investor-phone'));
                }
            }

            // Update phone in state and submit registration form
            yield put(commonRegistrationStateHandler.update({
                phone: values.phone,
            }));

            yield put(commonRegistrationFormHandler.update(values));
            yield put(commonRegistrationFormHandler.submit(values));

            // Skip this API request for partner users.
            if (values?.clientType === ClientType.Partner) {
                return undefined;
            }

            return values;
        },
        *onSuccess () {
            // Method successfully completes, mark user as non exists
            yield put(commonRegistrationStateHandler.update({
                userExists: undefined,
                isChangePhonePressed: undefined,
            }));
        },
        *onFormError (message, _, { phone }) {
            // Method failed, mark user as existing
            // TODO Rework API to provide error_code field to identify type of error
            const state: IRegistrationState = yield select(commonRegistrationStateHandler.selector);

            if (message && message.includes('уже зарегистрирован')) {
                yield put(commonRegistrationStateHandler.update({
                    userExists: phone,
                    isChangePhonePressed: state.stage !== CommonStage.Phone ? undefined : true,
                    errorMessage: message,
                    stage: CommonStage.Phone,
                }));
            }
        },
        echo: true,
    }, {}
);

export const commonRegistrationFormHandler = simpleFormHandler<IRegistrationFormValues>(
    PREFIX, 'form', {}, {
        *onBeforeSubmit (values: IPhoneRegistrationFormValues) {
            yield put(commonRegistrationStateHandler.update({
                stage: CommonStage.Sms,
            }));

            // Fix client type from state in case it missed from form
            if (!values?.clientType) {
                const state: IRegistrationState = yield select(commonRegistrationStateHandler.selector);
                values.clientType = state.clientType;
            }

            const {
                clientType,
                phone,
                ...payload
            } = values;

            const smsId = yield signDataWithSmsSaga(clientType, phone, payload, 'register');
            if (!smsId) {
                // Data was not signed with SMS, return back on phone input step
                yield put(commonRegistrationStateHandler.update({
                    stage: CommonStage.Phone,
                }));

                return undefined;
            }

            const state: IRegistrationState = yield select(commonRegistrationStateHandler.selector);
            if (state.userExists) {
                // User already exists return back on phone input step
                yield put(commonRegistrationStateHandler.update({
                    stage: CommonStage.Phone,
                }));

                return undefined;
            }

            // Show spinner on Registration modal
            yield put(commonRegistrationStateHandler.update({
                stage: CommonStage.Loading,
                loadingTitle: 'Создание аккаунта...',
            }));

            const output: IRegistrationFormValues = {
                ...values,
                device_type: isMobile
                    ? RegistrationDeviceType.MobileWeb
                    : RegistrationDeviceType.DesktopWeb,
                sms_id: smsId,
            };

            return setRedirectUriFormValues(output);
        },
        apiMethod: apiPostUserRegistration,
        *onSuccess (response: ApiDataResponse<IRegistrationResultApiModel>, values) {
            const { clientType } = values;

            let redirectUri = response.data?.redirect_uri;
            if (isEmptyValue(redirectUri)) {
                redirectUri = CLIENT_TYPE_CABINET_URLS[clientType];
            }

            if (clientType) {
                yield put(sendEvent(`${clientType}--guest-registration`));
            }

            if (redirectUri) {
                yield put(commonRegistrationStateHandler.update({
                    redirectUri,
                }));
            }

            // Especially for investors we need to show Email form before goes inside cabinet
            if (clientType === ClientType.Investor) {
                yield put(commonRegistrationStateHandler.update({
                    stage: CommonStage.Email,
                }));

                return;
            }

            // Redirect user by Redirect URI or by default Cabinet URI based on client type
            document.location.href = redirectUri;
        },
        *onFormError (message, resp, values) {
            const phone = values.phone;
            const state: IRegistrationState = yield select(commonRegistrationStateHandler.selector);

            // TODO Rework API to provide error_code field to identify type of error
            const isUserExistsError = message && message.includes('уже зарегистрирован');

            // In case of errors, return back to phone form
            yield put(commonRegistrationStateHandler.update({
                stage: CommonStage.Phone,
                userExists: isUserExistsError && state.stage !== CommonStage.Phone ? phone : undefined,
            }));
        },
    }, {}
);


export const commonRegistrationEmailFormHandler = simpleFormHandler<IEmailFormValues>(
    PREFIX, 'email_form', {
        v2: {
            email: [ required(), requireEmail() ],
        },
    }, {
        *onBeforeSubmit (values: IEmailFormValues) {
            // Fix client type from state in case it missed from form
            if (!values?.clientType) {
                const state: IRegistrationState = yield select(commonRegistrationStateHandler.selector);
                values.clientType = state.clientType;
            }

            if (values?.clientType) {
                yield put(sendEvent(`${values?.clientType}--save-email`));
            }

            return values;
        },
        apiMethod: apiPostProfileEmailUpdate,
        *onSuccess (_, values) {
            const { clientType } = values;
            const state: IRegistrationState = yield select(commonRegistrationStateHandler.selector);

            const redirectUri = state?.redirectUri;

            // Redirect user by Redirect URI or by default Cabinet URI based on client type
            if (!isEmptyValue(redirectUri)) {
                document.location.href = redirectUri;
            } else {
                document.location.href = CLIENT_TYPE_CABINET_URLS[clientType];
            }
        },
    }, {}
);

/**
 * Form handler для работы с формой вариантов сторонней авторизации.
 */
export const registerViaExternalAuthFormHandler = simpleFormHandler<IExternalLoginValues>(PREFIX, 'external_auth_form', undefined, {
    apiMethod: apiPostExternalUserLogin,
    *onBeforeSubmit (data: Omit<IExternalLoginValues, 'clientType'>) {
        const state = yield select(commonRegistrationStateHandler.selector);
        const { clientType }: IRegistrationState = state;

        if (clientType) {
            yield put(sendEvent(`${clientType}--external-login--${data.auth_variant}`));
        }

        const output: IExternalLoginValues = {
            ...data,
            clientType,
            return_uri: CLIENT_TYPE_REGISTRATION_URLS[clientType],
            device_type: isMobile
                ? LoginDeviceType.MobileWeb
                : LoginDeviceType.DesktopWeb,
        };

        return output;
    },
    onSuccess (response: ApiDataResponse<ILoginResultApiModel>, values) {
        const redirectUri = response.data?.redirect_uri;
        const { clientType } = values;

        if (!isEmptyValue(redirectUri)) {
            document.location.href = redirectUri;
        } else {
            document.location.href = CLIENT_TYPE_CABINET_URLS[clientType];
        }
    },
    *onFormError (message) {
        yield put(addToastError(message));
    },
}, {});

