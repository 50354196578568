'use client';

import React, { useMemo } from 'react';
import { usePathname } from 'next/navigation';
import ResponsiveMenuDropdownAction from '@frontend/jetlend-web-ui/src/components/ResponsiveMenu/ResponsiveMenu.DropdownAction';
import ResponsiveMenuButton from '@frontend/jetlend-web-ui/src/components/ResponsiveMenu/ResponsiveMenu.Button';
import capitalize from '@frontend/jetlend-core/src/formatters/capitalize';
import { IPagesMenuApiModel } from '@app/models/pages/pages';

const getFormattedPathname = (pathname: string) => {
    const firstPart = pathname.split('/')[1];

    return `/${firstPart}/`;
};

/**
 * Компонент элемента из списка меню
 */
export default function LayoutHeaderMenuItem({
    title,
    url,
    submenu_items,
}: IPagesMenuApiModel) {
    const pathname = usePathname();

    const dropdownListData = useMemo(() => submenu_items?.map(item => ({
        ...item,
        href: item?.url,
    })), [submenu_items]);

    return (
        <ResponsiveMenuDropdownAction
            href={url}
            title={capitalize(title)}
            active={url === getFormattedPathname(pathname)}
        >
            {dropdownListData?.map(item => (
                <ResponsiveMenuButton key={item.title} href={item.href} title={item.title} />
            ))}
        </ResponsiveMenuDropdownAction>
    );
};
