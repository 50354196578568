import {
    all,
    call,
    put,
    takeEvery,
    select,
} from 'redux-saga/effects';
import { objectHandler } from '@frontend/jetlend-core/src/ducks/object';
import { simpleApiHandler } from '@frontend/jetlend-core/src/ducks/api/simple';
import {
    modalFormHandler,
    simpleFormHandler,
} from '@frontend/jetlend-core/src/ducks/form';
import { collectionApiHandler } from '@frontend/jetlend-core/src/ducks/api';
import { safeSaga } from '@frontend/jetlend-core/src/ducks/utils';
import { simpleSagaHandlerV2 } from '@frontend/jetlend-core/src/ducks/saga_v2';
import {
    CoursesFormatTags,
    CoursesProgressTags,
    CoursesTags,
    CoursesTagsType,
    IAchievementItemApiModel,
    IAchievementSeenPayload,
    ICourseBlockPassPayload,
    ICourseBlockSeenPayload,
    ICourseInformationApiModel,
    ICoursesState,
    ICourseStateApiModel,
    ICourseStore,
    IUserCoursesItemApiModel,
} from '@app/models/academy/course';
import {
    apiGetAchievementsHandler,
    apiGetAllUserCoursesHandler,
    apiGetCourseStateHandler,
    apiGetRecommendedCoursesHandler,
    apiPostAchievementSeenHandler,
    apiPostMarkCourseBlockAsPassed,
    apiPostMarkCourseBlockAsSeen,
} from '@app/services/client/academy/course';
import { getCoursesById } from '@app/components/client/academy/academy.helper';

export const VERSION = 2;
export const PREFIX = 'course';

/**
 * Текущее хранилище курса, внутри которого содержится:
 * - Название(slug) курса
 * - Информация о текущем уроке
 */
export const courseStoreHandler = objectHandler<ICourseStore>(PREFIX, 'store', () => ({
    course_slug: null,
    current_block_id: null,
    format: null,
    course_id: null,
}));

/**
 * Текущее хранилище страницы курсов, внутри которого содержится:
 * - Все курсы
 * - Отфильтрованные курсы
 * - Выбранный фильтр
 * - Тип фильтра
 * - Текст поиска
 */
export const coursesPageStateHandler = objectHandler<ICoursesState>(
    PREFIX, 'state', () => ({
        courses: [],
        filteredCourses: [],
        selectedTag: null,
        filterType: null,
        search: '',
    })
);

/**
 * Получение информации по рекомендованным курсам
 */
export const getRecommendedCoursesHandler = simpleApiHandler<ICourseInformationApiModel[]>(
    PREFIX,
    'recommended',
    apiGetRecommendedCoursesHandler
);

/**
 * Получение информации по курсам пользователя
 */
export const getUserCoursesHandler = simpleApiHandler<IUserCoursesItemApiModel[]>(
    PREFIX,
    'user-courses',
    apiGetAllUserCoursesHandler
);

/**
 * Получение информации по наградам пользователя
 */
export const getAchievementsHandler = simpleApiHandler<IAchievementItemApiModel[]>(
    PREFIX,
    'achievements',
    apiGetAchievementsHandler
);

/**
 * Получение стейта курса из API
 */
export const getCourseStateHandler = collectionApiHandler<number, ICourseStateApiModel>(
    PREFIX, 'state', 'course_state', apiGetCourseStateHandler
);

/**
 * Прохождение урока, где нет теста
 */
export const passCourseLessonHandler = simpleFormHandler(
    PREFIX,
    'pass-lesson',
    {},
    {
        apiMethod: apiPostMarkCourseBlockAsPassed,
        *onSuccess (_, { course_id }: ICourseBlockPassPayload) {
            if (!course_id) {
                return;
            }

            yield put(getCourseStateHandler.fetchUpdate(course_id));
        },
    },
    {}
);

/**
 * Хендлер отмечающий блок курса как просмотренный
 */
export const courseMarkAsSeenHandler = simpleFormHandler<ICourseBlockSeenPayload>(
    PREFIX, 'mark_as_seen', {}, {
        apiMethod: apiPostMarkCourseBlockAsSeen,
    }, {}
);

/**
 * Управление модальным окном ачивок
 */
export const achievementModalHandler = modalFormHandler(
    PREFIX, 'achievement_modal', {}, {
        apiMethod: apiPostAchievementSeenHandler,
        onSuccessUpdate: [
            getAchievementsHandler,
        ],
    }
);

/**
 * Слушатель, открывает модальное окно с новой наградой
 */
function* onAchievementModalSaga() {
    const achievements: IAchievementItemApiModel[] = yield select(getAchievementsHandler.selector);
    // Проверяем, что есть хоть одна награда, которую нужно отобразить
    const isAchievementModalShow = achievements?.some(achievement => achievement?.show);

    if (isAchievementModalShow) {
        return yield put(achievementModalHandler.open());
    }
}

/**
 * Отправляем информацию, что пользователь увидел новую награду
 */
export const achievementViewedSagaHandler = simpleSagaHandlerV2(PREFIX, 'achievement_viewed', function*(payload: IAchievementSeenPayload) {
    yield call(apiPostAchievementSeenHandler, payload);
});

export const achievementViewedSaga = (payload: IAchievementSeenPayload) => achievementViewedSagaHandler.action(payload);

/**
 * Инициализация курсов в хранилище
 */
export const updateCoursesSagaHandler = simpleSagaHandlerV2(PREFIX, 'update_courses', function* (courses: ICourseInformationApiModel[]) {
    yield put(coursesPageStateHandler.update({
        courses,
        filteredCourses: courses,
    }));
});

/**
 * Поиск по курсам
 */
function searchCourses(courses: ICourseInformationApiModel[], search: string) {
    if (!search || search?.length === 0) {
        return courses;
    }

    const filteredCourses = courses.filter(course => course.title.toLowerCase().includes(search.toLowerCase()));

    return filteredCourses;
}

export const updateCourses = (courses: ICourseInformationApiModel[]) => updateCoursesSagaHandler.action(courses);

/**
 * Фильтрация и поиск по курсам
 */
export const filterAndSearchCoursesSagaHandler = simpleSagaHandlerV2(PREFIX, 'filter_courses', function* (payload: {search: string; selectedTag: CoursesTags; filterType: CoursesTagsType}) {
    const {
        search = '',
        selectedTag,
        filterType,
    } = payload;
    const state: ICoursesState = yield select(coursesPageStateHandler.selector);
    const userCourses = yield select(getUserCoursesHandler.selector);
    const userCoursesMap = getCoursesById(userCourses);

    if (!selectedTag) {
        return yield put(coursesPageStateHandler.update({
            filteredCourses: searchCourses(state?.courses, search),
            selectedTag: null,
            filterType: null,
        }));
    }

    const filteredCourses = state?.courses?.filter(course => {
        const userCourse = userCoursesMap[course?.id];
        if (filterType === 'complexity') {
            return course?.complexity === selectedTag;
        }
        if (filterType === 'format' && selectedTag === CoursesFormatTags.Content) {
            return course?.content_estimated_time;
        }
        if (filterType === 'format' && selectedTag === CoursesFormatTags.Video) {
            return course?.video_estimated_time;
        }
        if (filterType === 'progress' && selectedTag === CoursesProgressTags.Passed) {
            return userCourse?.passed;
        }
        if (filterType === 'progress' && selectedTag === CoursesProgressTags.Active) {
            return !userCourse?.passed && userCourse?.passed_blocks_count > 0;
        }

        return false;
    });

    yield put(coursesPageStateHandler.update({
        filteredCourses: searchCourses(filteredCourses, search),
        selectedTag,
        filterType,
    }));
});

export const filterAndSearchCourses = (search: string, selectedTag: CoursesTags, filterType: CoursesTagsType) => filterAndSearchCoursesSagaHandler.action({
    search,
    selectedTag,
    filterType,
});

/**
 * Получение фильтров и вызов функции фильтрации
 */
export const applyNewCoursesTagsFilterSagaHandler = simpleSagaHandlerV2(PREFIX, 'apply_courses_filter', function* (payload: {selectedTag: CoursesTags; filterType: CoursesTagsType}) {
    const {
        selectedTag,
        filterType,
    } = payload;
    const state: ICoursesState = yield select(coursesPageStateHandler.selector);
    const newSelectedTag = state?.selectedTag === selectedTag ? null : selectedTag;

    yield put(filterAndSearchCourses(state?.search, newSelectedTag, filterType));
});

export const applyCoursesTagsFilter = (selectedTag: CoursesTags, filterType: CoursesTagsType) => applyNewCoursesTagsFilterSagaHandler.action({
    selectedTag,
    filterType,
});

/**
 * Получение текста поиска и вызов функции фильтрации
 */
export const applyNewCoursesSearchSagaHandler = simpleSagaHandlerV2(PREFIX, 'search_courses', function* (search: string) {
    const state: ICoursesState = yield select(coursesPageStateHandler.selector);

    yield put(filterAndSearchCourses(search, state?.selectedTag, state?.filterType));

    yield put(coursesPageStateHandler.update({
        search,
    }));
});

export const applyCoursesSearch = (searchText?: string) => applyNewCoursesSearchSagaHandler.action(searchText);

export function* subscriptions() {
    yield all([
        takeEvery(getAchievementsHandler.FETCH_DONE, safeSaga(onAchievementModalSaga, PREFIX)),
    ]);
}