import React, { useCallback } from 'react';
import iconChevron from '@frontend/jetlend-assets/icons/icon-chevron--down.svg';
import { getAssetSrc } from '@frontend/jetlend-web-ui/src/utils/getAssetSrc';
import Tooltip from '../../ui/TooltipIcon/Tooltip';
import {
    isMobile,
    useWindowSize,
} from '@ui/utils/responsive';

import styles from './selectUserButton.module.scss';
import UserIcon from '@ui/ui/UserIcon/UserIcon';

export interface IProps extends React.PropsWithChildren {
    /**
     * Заголовок
     */
    name: React.ReactNode | JSX.Element;
    /**
     * Дефолтное имя пользователя
     */
    defaultUserName?: React.ReactNode | JSX.Element;
    /**
     * Подзаголовок
     */
    sub?: React.ReactNode|JSX.Element;
    /**
     * Иконка
     */
    image?: string;

    /**
     * Флаг, показывающий, что кнопка заблокирована
     */
    disabled?: boolean;
    /**
     * Флаг, показывающий, что кнопка в процессе загрузки
     */
    loading?: boolean;
    /**
    * Всплывающая подсказка
    */
    hint?: string;
    /**
     * Бадж над иконкой пользователя
     */
    badge?: React.ReactNode;
    /**
     * Идентификатор пользователя
     */
    id?: number;
    /**
     * Обработчик клика
     */
    onClick?: () => void;
}

/**
 * Кнопка выбора пользователя
 */
export default function SelectUserButton({
    name,
    defaultUserName = '',
    sub,
    image,
    disabled,
    loading,
    onClick,
    children,
    hint = 'Авторизованный пользователь',
    badge,
    id,
}: IProps) {
    const { width: screenWidth } = useWindowSize();
    const isMobileScreen = isMobile(screenWidth);

    const didButtonClicked = useCallback(() => {
        if (disabled) {
            return;
        }

        if (onClick) {
            onClick();
        }
    }, [disabled, onClick]);

    if (loading) {
        return;
    }

    return (
        <Tooltip title={hint} disabled={isMobileScreen}>
            <div className={styles['container']}>
                <button className={styles['button']} onClick={didButtonClicked} type="button">
                    <UserIcon
                        className={styles['button__wrapper']}
                        badge={badge}
                        image={image}
                        name={name}
                        id={id}
                    />
                    <div className={styles['button__content']}>
                        <div className={styles['button__name']}>
                            {name ?? defaultUserName}
                        </div>
                        {sub && (
                            <div className={styles['button__sub']}>
                                {sub}
                            </div>
                        )}
                    </div>

                    <img src={getAssetSrc(iconChevron)} alt="" />
                </button>
                {children && React.Children.count(children) > 0 && (
                    <div className={styles['button__tail']}>
                        {children}
                    </div>
                )}
            </div>
        </Tooltip>
    );
}
