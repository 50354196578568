import React from 'react';
import {
    IWizardStep,
    WizardStepType,
} from '@frontend/jetlend-web-ui/src/components/Wizard/WizardBreadCrumbButton';
import { SectionStretchType } from '@app/components/server/ui/Section/Section';
import { AnimationType } from '@app/components/server/ui/animations';
import yandexIcon from '@app/icons/yandex-icon.svg';
import googlePlayIcon from '@app/icons/google-play-icon.svg';
import appStoreIcon from '@app/icons/app-store-icon.svg';
import { BorrowersSectionType } from '@app/models/borrowers/borrowers';
import { InvestorsSectionType } from '@app/models/investors/investors';
import { PartnersSectionType } from '@app/models/partners/partners';
import { AcademySectionType } from '@app/models/academy/course';
import { InvestmentsSectionType } from '@app/models/investments/investments';

export enum SectionType {
    EXTERNAL_NEWS = 'external_news',
    PARTNERS = 'partners',
    FEEDBACKS = 'feedbacks',
    YOUTUBE = 'youtube',
    STATISTICS = 'statistics',
    INDICATORS = 'indicators',
    MARKUP = 'markup',
    MARKUP_BLOCKS = 'markup_blocks',
    ROLLUP = 'rollup',
    STEPS = 'steps',
    REPORTS = 'reports',
}

export interface ISectionBasedProps<ISectionApiModel> {
    section: ISectionApiModel;
    filled?: boolean;
}

/**
 * Интерфейс стандартной секции с описанием
 */
export type BaseSectionWithBlocksAndDescription<T> = BaseSectionWithBlocks<T> & {
    /**
     * Описание
     */
    description: string;
}

/**
 * Апи модель блока секции
 */
export interface ISectionBlockApiModel {
    /**
     * Заголовок
     */
    title: string;
    /**
     * Значение
     */
    value?: string;
    /**
     * Описание
     */
    description?: string;
    /**
     * Значение с описанием
     */
    value_description?: string;
    /**
     * Иконка
     */
    image?: string;
}

/**
 * Апи модель фича блока
 */
export interface IFeatureBlockApiModel<TModel> extends ISectionBlockApiModel {
    /**
     * Идентификатор фича блока
     */
    id: TModel;
}

export interface ISectionConfig {
    component: React.ComponentType;
    hideTitle?: boolean;
    filled?: boolean;
    stretch?: SectionStretchType;
    action?: React.ReactNode | JSX.Element;
    animation?: AnimationType;
}

export interface IBaseSectionApiModel<TType = SectionType> {
    type: TType;
    title: string;
    title_mobile?: string;
    description?: string;
    show_registration_form?: boolean;
    hidden: boolean;
}

export type ExtendedBaseSectionApiModel = IBaseSectionApiModel<SectionType | InvestorsSectionType | BorrowersSectionType | PartnersSectionType | AcademySectionType | InvestmentsSectionType> & {
    [p: string]: any;
}

export type BaseSectionWithBlocks<TBlock> = IBaseSectionApiModel & {
    blocks: TBlock[];
}

export type BaseSectionWithItems<TItem> = IBaseSectionApiModel & {
    items: TItem[];
}

export type BaseSectionWithDocuments<TItem> = IBaseSectionApiModel & {
    documents: TItem[];
}

export enum StepsSectionStepType {
    DEFAULT = 'default',
    PRIMARY = 'primary',
}

export interface IStepsSectionStepModel {
    title: string;
    url?: string;
    type: StepsSectionStepType;
}

export enum StepsSectionContentType {
    CHART = 'chart',
    BROKERS = 'brokers',
}

export interface IStepsSectionBrokerModel {
    title: string;
    icon?: string;
    url?: string;
}

export interface IStepsSectionContentBaseModel {
    type: StepsSectionContentType;
}

export interface IStepsSectionBrokersContentModel {
    type: StepsSectionContentType.BROKERS;
    brokers?: IStepsSectionBrokerModel[];
}

export interface IStepsSectionChartContentModel {
    type: StepsSectionContentType.CHART;
    chart_id?: string;
    title?: string;
    source_title?: string;
    source_url?: string;
}

export type StepsSectionContentModel = IStepsSectionBrokersContentModel|IStepsSectionChartContentModel;

export interface IBaseSectionWithStepsModel<TContentType extends StepsSectionContentType = any> extends IBaseSectionApiModel {
    steps: IStepsSectionStepModel[];
    content: TContentType extends StepsSectionContentType.CHART
        ? IStepsSectionChartContentModel
        : TContentType extends StepsSectionContentType.BROKERS
        ? IStepsSectionBrokersContentModel
        : StepsSectionContentModel;
}

export interface IReportsSectionReportModel {
    title: string;
    icon: string;
    value: string;
    url: string;
}

export interface IBaseSectionWithReportsModel extends IBaseSectionApiModel {
    price: string;
    reports: IReportsSectionReportModel[];
}

export enum ClientType {
    Investor = 'investor',
    Borrower = 'borrower',
    Partner = 'partner',
}

export interface IAppBadge {
    src: string;
    href: string;
    title: string;
}

export const CLIENT_TYPE_SCOPE_TITLE: Record<ClientType, string> = {
    [ClientType.Investor]: 'инвестор',
    [ClientType.Borrower]: 'заемщик',
    [ClientType.Partner]: 'партнер',
};

export const CLIENT_TYPE_SCOPE_MAP: Record<ClientType, string> = {
    [ClientType.Investor]: 'client',
    [ClientType.Borrower]: 'business',
    [ClientType.Partner]: 'partner',
};

export const CLIENT_TYPE_LOGIN_URLS: Record<ClientType, string> = {
    [ClientType.Investor]: '/invest/login/',
    [ClientType.Borrower]: '/lend/login/',
    [ClientType.Partner]: '/partner/login/',
};

export const CLIENT_TYPE_RESET_PASS_URLS: Record<ClientType, string> = {
    [ClientType.Investor]: '/invest/reset/',
    [ClientType.Borrower]: '/lend/reset/',
    [ClientType.Partner]: '/partner/reset/',
};

export const CLIENT_TYPE_REGISTRATION_URLS: Record<ClientType, string> = {
    [ClientType.Investor]: '/invest/register/',
    [ClientType.Borrower]: '/lend/register/',
    [ClientType.Partner]: '/partner/register/',
};

export const CLIENT_TYPE_CREATE_URLS: Record<ClientType, string> = {
    [ClientType.Investor]: '/invest/create/',
    [ClientType.Borrower]: '/lend/create/',
    [ClientType.Partner]: '/partner/create/',
};

export const CLIENT_TYPE_CABINET_URLS: Record<ClientType, string> = {
    [ClientType.Investor]: '/invest/v3',
    [ClientType.Borrower]: '/lend/v3',
    [ClientType.Partner]: '/partner/v3',
};

export enum CommonStage {
    Phone = 'phone',
    Sms = 'sms',
    ResetPassword = 'reset_password',
    Email = 'email',
    Loading = 'loading',
    NewPassword = 'new_password',
    Login = 'login',
    Create = 'create',
    Registration = 'registration',
    OauthPhone = 'oauth_phone',
}

export const getClientTitle = (clientType: string) => clientType ? `${clientType}a` : '';

export function getClientTypeFromUrl(url: string): ClientType|undefined {
    if (!url) {
        return undefined;
    }

    if (url.includes('/invest/')) {
        return ClientType.Investor;
    }

    if (url.includes('/lend/')) {
        return ClientType.Borrower;
    }

    if (url.includes('/partner/')) {
        return ClientType.Partner;
    }

    return undefined;
}

export const getCommonSteps = (stage: CommonStage): IWizardStep[] => ([
    {
        id: 0,
        title: 'Ввод телефона',
        type: WizardStepType.Completed,
    },
    {
        id: 1,
        title: 'Подтверждение телефона',
        type: (stage === CommonStage.Sms || stage === CommonStage.Email) && WizardStepType.Completed,
    },
    {
        id: 3,
        title: 'Ввод email',
        type: stage === CommonStage.Email && WizardStepType.Completed,
    },
]);

/**
 * Ссылки на страницы с тарифами клиентов
 */
export const CLIENT_TYPE_CABINET_TARIFF_URLS: Record<ClientType, string> = {
    [ClientType.Investor]: '/invest/v3/profile/tariffs',
    [ClientType.Borrower]: '/lend/v3/statuses',
    [ClientType.Partner]: '',
};

/**
 * Наименования типов клиентов
 */
export const CLIENT_TYPE_NAMES: Record<ClientType, string> = {
    [ClientType.Investor]: 'Инвестор',
    [ClientType.Borrower]: 'Заемщик',
    [ClientType.Partner]: 'Партнер',
};

export interface IAuthorApiModel {
    name: string;
    icon: string;
}

type IFeedbacksService = 'Яндекс' | 'Google Play' | 'App Store';

export const SERVICE_ICONS_MAP: Record<IFeedbacksService, string> = {
    'Яндекс': yandexIcon,
    'Google Play': googlePlayIcon,
    'App Store': appStoreIcon,
};

export type FeedbacksSectionApiModel = BaseSectionWithItems<FeedbackItemApiModel>;

export interface FeedbackItemApiModel {
    text: string;
    service: string;
    username: string;
    rating: string;
    date: string;
}

export type PartnersSectionApiModel = BaseSectionWithItems<PartnerItemApiModel>

export interface PartnerItemApiModel {
    title: string;
    image: string;
    url: string;
}

export type YouTubeSectionApiModel = BaseSectionWithItems<IYouTubeItemApiModel>

export interface IYouTubeItemApiModel {
    title: string;
    author?: IYouTubeItemAuthorApiModel;
    date: string;
    url: string;
    preview_url: string;
    small_preview_url: string;
}

export interface IYouTubeItemAuthorApiModel extends IAuthorApiModel {
    verified: boolean;
}

export interface IExternalNewsSectionApiModel extends IBaseSectionApiModel {
    posts: IExternalNewsPostApiModel[];
}

export interface IExternalNewsPostApiModel {
    title: string;
    description: string;
    author: IAuthorApiModel;
    date: string;
    url: string;
}

/**
 * Типы фича блоков
 */
export enum HistoryFeatureType {
    YEAR_2019 = 'year_2019',
    YEAR_2020 = 'year_2020',
    YEAR_2021 = 'year_2021',
    YEAR_2022 = 'year_2022',
    YEAR_2023 = 'year_2023',
    YEAR_2024 = 'year_2024',
}
