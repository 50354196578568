'use client';

import React, {
    useCallback,
    useRef,
} from 'react';

import Tippy, { TippyProps } from '@tippyjs/react';
import { Placement as TippyPosition } from 'tippy.js';
import { followCursor as followCursorPlugin } from 'tippy.js';
import {
    buildClassNames,
    mergeClassNames,
} from '../../utils/classNameUtils';

import styles from './tooltipIcon.module.scss';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-away.css';
import { useContainerWrapper } from '@ui/hooks/useContainerWrapper';

const TOOLTIP_DELAY_MS = 175;

export interface TooltipProps extends React.PropsWithChildren {
    /**
     * Заголовок
     */
    title: string | JSX.Element | React.ReactNode;
    /**
     * Расположение подсказки относительно элемента
     */
    position?: TippyPosition;
    /**
     * Название класса
     */
    className?: string;
    /**
     * Название класса контейнера
     */
    containerClassName?: string;
    /**
     * Насколько далеко в пикселях подсказка находится от элемента
     */
    distance?: number;
    /**
     * Определяет смещение элемента
     */
    offset?: number;
    /**
     * Определяет, есть ли у подсказки стрелка
     */
    arrow?: boolean;
    /**
     * Заблокирован ли элемент
     */
    disabled?: boolean;
    /**
     * Добавляет (display: inline)
     */
    disableWrapper?: boolean;
    /**
     * Определяет, является ли подсказка интерактивной, т.е. на нее можно навести курсор или щелкнуть
     */
    interactive?: boolean;
    /**
     * Ссылка на элемент
     */
    reference?: React.RefObject<Element> | Element | null;
    /**
     * Хендлер для отображения подсказки
     */
    onOpen?: () => void;
    /**
     * Хендлер для скрытия подсказки
     */
    onClose?: () => void;
    /**
     * Определяет, должен ли подсказка следовать курсору
     */
    followCursor?: boolean;
}

/**
 * Компонент всплывающей подсказки
 */
export default function Tooltip({
    title,
    children,
    disableWrapper,
    containerClassName,
    distance,
    interactive,
    disabled,
    arrow,
    className,
    offset,
    position,
    onOpen,
    onClose,
    reference,
    followCursor,
}: TooltipProps) {

    const didOpened = useCallback(
        () => onOpen && onOpen(),
        [onOpen]
    );

    const didClosed = useCallback(
        () => onClose && onClose(),
        [onClose]
    );

    const tooltipClassName = mergeClassNames([
        buildClassNames(styles, ['tooltip-container']),
        className,
    ]);

    const commonProps: TippyProps = {
        delay: TOOLTIP_DELAY_MS,
        offset: [offset || 0, distance || 10],
        placement: position || 'bottom',
        className: tooltipClassName,
        arrow,
        disabled,
        onShow: didOpened,
        onHide: didClosed,
        animation: 'shift-away',
        maxWidth: 400,
        zIndex: 9999,
        interactive,
        reference,
        followCursor,
        plugins: [followCursorPlugin],
    };

    const containerRef = useRef<HTMLDivElement>(null);

    useContainerWrapper(containerRef, [children]);

    if (disableWrapper) {
        if (title) {
            return (
                <Tippy content={title} inlinePositioning={disableWrapper} {...commonProps}>
                    {children as any}
                </Tippy>
            );
        }

        return children;
    }

    if (title) {
        return (
            <Tippy content={title} {...commonProps}>
                <div ref={containerRef} className={containerClassName}>
                    {children}
                </div>
            </Tippy>
        );
    }

    return <div className={containerClassName}>{children}</div>;
};