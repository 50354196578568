'use client';

import React, { useRef } from 'react';
import { useWindowResizeEffect } from '@frontend/jetlend-web-ui/src/hooks/useWindowResizeEffect';
import { ISectionBasedProps } from '@app/models/common/common';
import { IMarkupBlocksSectionApiModel } from '@app/models/common/page';
import MarkupBlock from './MarkupBlock/MarkupBlock';
import styles from './MarkupBlocksSection.module.scss';

function handleContainerResize(container: HTMLDivElement) {
    if (!container) {
        return;
    }

    // Сперва сбрасываем переменную, чтобы корректно вычислить максимальную высоту
    container.style.setProperty('--markup-block--header-height', '');

    // Для мобильных экранов нам нужно только сбросить переменную
    // т.к. на мобильных экранах карточки независимые.
    const isMobileScreen = window.innerWidth <= 1000;
    if (isMobileScreen) {
        return;
    }

    // Получаем все заголовки в блоках и считаем максимальную высоту
    const headers = Array.from(container.querySelectorAll('h3'));
    const maxHeaderHeight = headers.reduce((accumulator, header) => {
        return Math.max(accumulator, header.clientHeight);
    }, 0);

    // Устанавливаем переменную с новой расчетной высотой
    container.style.setProperty('--markup-block--header-height', `${Math.round(maxHeaderHeight)}px`);
}

export default function MarkupBlocksSection({ section }: ISectionBasedProps<IMarkupBlocksSectionApiModel>) {
    const { blocks } = section ?? {};

    const containerRef = useRef<HTMLDivElement>();
    useWindowResizeEffect(() => {
        handleContainerResize(containerRef.current);
    }, [ containerRef ]);

    if (!blocks || blocks.length === 0) {
        return null;
    }

    return (
        <div ref={containerRef} className={styles['container']}>
            {blocks.map(block => (
                <MarkupBlock
                    key={block.title}
                    className="d-flex--1"
                    {...block}
                />
            ))}
        </div>
    );
};