import { useSelector } from 'react-redux';
import { IStoreHandler } from '@core/ducks/store';
import { useActions } from './useActions';

type HookReturnType<TModel> = [
    TModel,
    (value: Partial<TModel>) => void
];

/**
 * Хук для работы с хендлерами, реализующие интерфейс {@link IStoreHandler} внутри компонента.
 * - {@link storeHandler}
 * - {@link localStoreHandler}
 * - {@link sessionStoreHandler}
 * - {@link asyncStorageStoreHandler}
 *
 * Работает по аналогии с {@link useObjectHandler}
 *
 * @param handler Хендлер
 * @returns Данные и функция для обновления состояния.
 * @category Hooks
 *
 * @see {@link storeHandler}
 * @see {@link localStoreHandler}
 * @see {@link sessionStoreHandler}
 * @see {@link asyncStorageStoreHandler}
 * @see {@link useObjectHandler}
 *
 * @example
 * const [
 *      // Состояние
 *      state,
 *      // Функция для обновления состояния
 *      updateState
 *  ] = useStoreHandler(dashboardDisplayState);
 */
export default function useStoreHandler<TModel>(handler: IStoreHandler<TModel>): HookReturnType<TModel> {
    const value = useSelector(handler.selector);
    const update = useActions(handler.update);

    return [
        value,
        update,
    ];
}
